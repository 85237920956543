import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Login from "./Login.js";
import Sent from "./Sent.js";
import OAuthLogin from "./OAuthLogin.js";
import DeleteAccount from "./DeleteAccount.js";

export default function LoginRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Login />} />
        <Route path="/sent" element={<Sent />} />
        <Route path="/oauth2callback" element={<OAuthLogin />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
