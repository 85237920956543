import React, { useContext } from "react";
import styled from "styled-components";
import Row from "./layout/Row.js";
import user from "../assets/icons/user.svg";
import { SessionContext } from "./ContextProvider.js";

const ProfileContainer = styled.div`
  background: transparent;
  border: none;
  padding: 2rem 2rem;
  margin: -2rem -2rem;
  outline: none;
  pointer-events: all;

  svg path,
  p {
    transition: 0.2s all;
  }
`;

export default function ProfileLabel() {
  const [session] = useContext(SessionContext);
  const sessionName = session.name;

  return (
    <ProfileContainer>
      <Row $gap="1rem" $center>
        <img src={user} alt="user" />
        <p>{sessionName}</p>
      </Row>
    </ProfileContainer>
  );
}
