import React from "react";
import Row from "./Row";
import styled from "styled-components";

const LeftDiv = styled.div`
  flex: 1;
  text-align: left;
`;

const RightDiv = styled.div`
  flex: 1;
  text-align: right;
`;

const HeaderBar = styled.div`
  margin-bottom: 2rem;
`;

function Header({ $left, $center, $right }) {
  return (
    <HeaderBar>
      <Row $justifySpaceBetween $center>
        <LeftDiv>{$left}</LeftDiv>
        <div>{$center}</div>
        <RightDiv>{$right}</RightDiv>
      </Row>
    </HeaderBar>
  );
}

export default Header;
