import React, { useContext, useState, useEffect } from "react";
import { convertToObject, getRequest } from "../../common.js";
import Collection from "../layout/Collection.js";
import CollectionRow from "../layout/CollectionRow.js";
import VideosList from "./VideosList.js";
import { ProgressContext } from "../ContextProvider";

export default function ContinueWatching() {
  const [progress] = useContext(ProgressContext);
  const [episodes, setEpisodes] = useState(null);
  const [videos, setVideos] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodes = await getRequest("/api/episode/all");
        setEpisodes(convertToObject(episodes, "episodeId"));
      } catch (err) {
        setError(err.message);
      }
    };

    fetchEpisodes();
  }, [progress]);

  useEffect(() => {
    if (progress && episodes) {
      try {
        const episodeIds = progress.order;
        const videos = episodeIds
          .filter((id) => id in episodes)
          .map((id) => episodes[id])
          .map((episode) => ({
            id: episode.episodeId,
            topTitle: episode.showTitle,
            title: episode.episodeTitle,
            path: episode.episodePath,
            url: episode.episodeUrl,
            showUrl: episode.showUrl,
          }));
        setVideos(videos);
      } catch (err) {
        setError(err.message);
      }
    }
  }, [progress, episodes]);

  return (
    videos &&
    videos.length > 0 && (
      <Collection>
        <h2>Continue Watching</h2>
        {error && <h1>{error}</h1>}
        {!error && (
          <CollectionRow>
            <VideosList tiles={videos} />
          </CollectionRow>
        )}
      </Collection>
    )
  );
}
