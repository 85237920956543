import React from "react";
import styled from "styled-components";
import AnkiCardViewContainer from "../../AnkiCardView.js";

const AnkiDeckWrapper = styled.div`
  overflow-y: auto;
`;

export default function AnkiDeckPopup({ movieId, breakdownIndex }) {
  return (
    <AnkiDeckWrapper>
      <AnkiCardViewContainer
        breakdownIndex={breakdownIndex}
        movieId={movieId}
      />
    </AnkiDeckWrapper>
  );
}
