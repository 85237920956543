import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { postRequest, microsToSeconds, getRequest } from "../../common.js";
import BackButton from "../atoms/BackButton.js";
import Scene from "./Scene.js";
import styled from "styled-components";
import { ProgressContext } from "../ContextProvider.js";
import NumberIcon from "../NumberIcon.js";
import { isIOS } from "react-device-detect";
import IconCompress from "../../assets/icons/compress.svg";
import IconExpand from "../../assets/icons/expand.svg";

import IconSquare from "../../assets/icons/square.svg";
import IconSquareCheck from "../../assets/icons/square-check.svg";

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  pointer-events: none;
`;

const TopButtonsShow = styled.div`
  display: flex;
  position: absolute;
  left: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(2rem + 18px + 4rem)"
      : "0"}; // 18px BackButton width & 4rem FullscreenButton width
  width: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(100% - 2rem - 18px - 4rem - 4rem - 58px)"
      : "100%"};
  min-height: calc(2rem + 44px);
  z-index: 2;
  cursor: pointer;
`;

const FullscreenButton = styled.button`
  &.fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    outline: none;
  }
`;

const TopBarButtonWrapper = styled.div`
  padding: 1rem;
`;

function parseTimeLiberally(time) {
  if (!time) return false;

  let nonMicrosStr = "";
  let micros = 0;
  if (time.indexOf(".") != -1) {
    let dotSplit = time.split(".");
    if (dotSplit.length != 2) {
      return false;
    }
    nonMicrosStr = dotSplit[0];
    micros = parseInt(dotSplit[1].padEnd(6, "0"));
    if (micros === null) {
      return false;
    }
  } else {
    nonMicrosStr = time;
    micros = 0;
  }

  var components = nonMicrosStr
    .split(":")
    .map((x) => parseInt(x, 10))
    .reverse();
  var seconds = 0;
  for (let i = 0; i < components.length; i++) {
    if (components[i] === null) {
      return false;
    }
    seconds += components[i] * Math.pow(60, i);
  }

  return seconds * 1000000 + micros;
}

function getInt(numberString) {
  if (typeof numberString !== "string" || numberString.includes("_")) {
    return -1;
  }
  try {
    return parseInt(numberString, 10);
  } catch (e) {
    return -1;
  }
}

function binarySearchRanges(ranges, num) {
  // This padding matches the padding from `scripts/cut_video.py`
  let padding = ("" + (ranges.length + 1)).length;
  let pad = (x) => ("" + x).padStart(padding, "0");
  let left = 0;
  let right = ranges.length - 1;
  let mid = 0;
  while (left <= right) {
    mid = Math.floor((left + right) / 2);
    const range = ranges[mid];
    // Range end is excluded on purpose, to make mutually exclusive ranges
    if (num >= range.start && num < range.end) {
      return pad(range.index_start);
    } else if (num < range.start) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }
  if (num < ranges[mid].start && mid - 1 < 0) {
    return pad("");
  } else if (num >= ranges[mid].end && mid + 1 >= ranges.length) {
    return pad(ranges[mid].index_start + 1);
  }
  const leftRange = num < ranges[mid].start ? ranges[mid - 1] : ranges[mid];
  const rightRange = num < ranges[mid].start ? ranges[mid] : ranges[mid + 1];

  // if gap is less than 2 seconds, find the nearest range
  // This gap matches the gap from `scripts/cut_video.py`
  if (microsToSeconds(rightRange.start - leftRange.end) <= 2) {
    if (num - leftRange.end < rightRange.start - num) {
      return pad(leftRange.index_start);
    } else {
      return pad(rightRange.index_start);
    }
  }

  // otherwise, return the clip of the gap
  return pad(leftRange.index_start) + "_" + pad(rightRange.index_start);
}

function FadeIn({ children }) {
  const divRef = useRef(null);
  setTimeout(() => {
    divRef.current.style.opacity = 1;
  }, 10);
  return (
    <div
      style={{ opacity: 0, transition: "opacity 0.5s ease-in-out" }}
      ref={divRef}
    >
      {children}
    </div>
  );
}

export default function MultiSwiper({
  config,
  episode,
  fragments,
  posterUrls,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  indicateReady,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [breakdownIndexToCardCount, setBreakdownIndexToCardCount] =
    useState(null);

  const fragmentsReversed = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(fragments).map(([idx, frag]) => [frag, +idx]),
      ),
    [fragments],
  );

  const mainSwiper = useRef(null);
  const evenSlideVideoElementRef = useRef(null);
  const oddSlideVideoElementRef = useRef(null);

  const [progress, setProgress] = useContext(ProgressContext);
  const [numberOfCards, setNumberOfCards] = useState(null);

  // The active scene is whichever scene is currently visible.
  const [activeScene, setActiveScene] = useState(null);
  // The transitional scene is the scene that will become active on the next
  // slide transition (assuming the user completes the swipe)
  const [transitionalScene, setTransitionalScene] = useState(null);
  // The last solo scene is the scene that was most recently visible by itself
  const [lastSoloScene, setLastSoloScene] = useState(null);

  const [subtitlesVisible, setSubtitlesVisible] = useState(false);
  const [popoverState, setPopoverState] = useState(null);
  const [popoverHasScrollbars, setPopoverHasScrollbars] = useState(false);
  const [popupState, setPopupState] = useState(null);
  const [isVisibleTopButtons, setIsVisibleTopButtons] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [subtitle, setSubtitle] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  const [activeBreakdown, setActiveBreakdown] = useState(config["default"]);
  const possibleBreakdowns = Object.keys(config["breakdowns"]);
  const subtitles = config.breakdowns[activeBreakdown];

  const SCENES_RENDERED_ON_EITHER_SIDE = 2;

  useEffect(() => {
    if (breakdownIndexToCardCount === null) {
      getRequest(
        "/api/anki/episode_cards_nr_by_scene/" + episode.id,
        {},
        setBreakdownIndexToCardCount,
      );
    }
  }, []);

  const toggleVisibility = () => {
    setIsVisibleTopButtons(!isVisibleTopButtons);
  };
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // for Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // for Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // for IE
        document.documentElement.msRequestFullscreen();
      } else {
        setIsFullscreen(false);
      }
    } else {
      setIsFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // for Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // for Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // for IE
        document.msExitFullscreen();
      } else {
        setIsFullscreen(true);
      }
    }
  };

  function createCardCallback(scene) {
    let breakdownIndex = getInt(fragments[scene]) - 1;

    if (breakdownIndex >= 0 && breakdownIndex < subtitles.length) {
      setBreakdownIndexToCardCount((current) => {
        if (current === null) return null;
        return {
          ...current,
          [breakdownIndex]: (current[breakdownIndex] ?? 0) + 1,
        };
      });
    }
  }

  // Note: A positive number means our slide moved right (moving back).
  const getSlideDisplacement = useCallback(() => {
    if (activeScene === null) return 0;
    const swiper = mainSwiper.current;
    if (!swiper) return 0;
    const displacementPixels =
      swiper.slidesGrid[activeScene] + swiper.translate;
    return displacementPixels / swiper.width;
  }, [activeScene]);

  const calculateTransitionalScene = useCallback(() => {
    const displacement = getSlideDisplacement();
    if (displacement == 0 || activeScene === null) {
      setTransitionalScene(null);
    } else {
      const index = activeScene - Math.sign(displacement);
      // Note: if we're swiping leftward, the transitional scene is the upcoming
      // one. If we're swiping rightward, the transitional scene is the previous
      // one.
      if (index >= 0 && index < fragments.length) {
        setTransitionalScene(index);
      } else {
        setTransitionalScene(null);
      }
    }
  }, [activeScene, fragments, getSlideDisplacement]);

  useEffect(() => {
    const swiper = mainSwiper.current;
    if (!swiper) return;

    const handleSlideChange = () => {
      const newScene = swiper.activeIndex;
      setActiveScene(newScene);
      calculateTransitionalScene();
      setPopupState(null);

      setProgress((progress) => ({
        ...progress,
        [episode.id]: swiper.progress,
        order: [
          episode.id,
          ...progress.order.filter((id) => id !== episode.id),
        ],
      }));
      postRequest("/api/progress", {
        episodeId: episode.id,
        progress: swiper.progress,
      });
    };

    swiper.on("slideChange", handleSlideChange);

    return () => {
      swiper.off("slideChange", handleSlideChange);
    };
  }, [
    episode,
    mainSwiper,
    calculateTransitionalScene,
    setPopupState,
    setProgress,
  ]);

  function handleBackButton() {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/browse/${episode.show.url}`);
    }
  }

  // Determine number of cards for scene on scene change
  useEffect(() => {
    let subIndex = getInt(fragments[activeScene]) - 1;
    if (
      subIndex >= 0 &&
      subIndex < subtitles.length &&
      breakdownIndexToCardCount !== null
    ) {
      setNumberOfCards(breakdownIndexToCardCount[subIndex] ?? 0);
    } else {
      setNumberOfCards(0);
    }
  }, [activeScene, breakdownIndexToCardCount, fragments, subtitles.length]);

  // Determine subtitle on scene change
  useEffect(() => {
    let titleComponents = [episode.title, episode.show.title];
    if (activeScene) {
      const subIndex = getInt(fragments[activeScene]) - 1;
      const subtitle =
        subIndex >= 0 && subIndex < subtitles.length
          ? subtitles[subIndex]
          : subIndex === -1
          ? { start_time: "00:00:00.000000" }
          : {
              start_time: subtitles[getInt(fragments[activeScene - 1]) - 1]
                ? subtitles[getInt(fragments[activeScene - 1]) - 1].end_time
                : "00:00:00.000000",
            };
      setSubtitle(subtitle);
      titleComponents.unshift(
        subtitle.start_time.split(".")[0].replace(/^0:/, ""),
      );
    }
    document.title = titleComponents.join(" - ");
  }, [activeScene, subtitles]);

  // Hide top buttons after 2.5 seconds
  useEffect(() => {
    let timer;
    if (isVisibleTopButtons) {
      timer = setTimeout(() => setIsVisibleTopButtons(false), 2500);
    }

    return () => clearTimeout(timer);
  }, [isVisibleTopButtons]);

  // Put the start time in the URL on scene change:
  useEffect(() => {
    if (subtitle.start_time) {
      window.history.replaceState(
        {},
        "", // See: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
        `/watch/${episode.show.url}/${episode.url}?time=${subtitle.start_time}`,
      );
    }
  }, [episode, activeScene, subtitle]);

  // Set scene on page load based on URL or progress
  useEffect(() => {
    if (mainSwiper.current && initialLoad) {
      setInitialLoad(false);
      const startTime = searchParams.get("time");
      const startTimeMicros = parseTimeLiberally(startTime);
      const fragment =
        startTimeMicros !== false &&
        binarySearchRanges(subtitles, startTimeMicros);
      const startScene =
        fragment && fragment in fragmentsReversed
          ? fragmentsReversed[fragment]
          : -1;
      // If we have progress for this episode, use it to set the scene.
      const hasProgress =
        progress &&
        episode.id in progress &&
        progress[episode.id] !== null &&
        !isNaN(progress[episode.id]);
      const newScene =
        startScene !== -1
          ? startScene
          : hasProgress
          ? Math.round(progress[episode.id] * fragments.length)
          : 0;
      if (newScene >= 0) {
        setActiveScene(newScene);
        mainSwiper.current.slideTo(newScene);
      }
    }
  }, [progress]);

  // Keyboard shortcuts
  useEffect(() => {
    function onKeyDown(e) {
      if (!mainSwiper.current) {
        e.preventDefault();
        return;
      }
      if (popupState) {
        return;
      }

      switch (e.code) {
        case "ArrowUp":
          e.preventDefault();
          setSubtitlesVisible(true);
          break;
        case "ArrowDown":
          e.preventDefault();
          setSubtitlesVisible(false);
          break;
        case "ArrowLeft":
          e.preventDefault();
          mainSwiper.current.slidePrev();
          break;
        case "ArrowRight":
          e.preventDefault();
          mainSwiper.current.slideNext();
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [subtitlesVisible, setSubtitlesVisible, popupState]);

  const breakdownSwitchOptions = useMemo(() => {
    return possibleBreakdowns.length > 1
      ? possibleBreakdowns.map((name) => ({
          title: name,
          label: (
            <span>
              <img
                src={name === activeBreakdown ? IconSquareCheck : IconSquare}
                width="16"
                height="16"
              />{" "}
              {name}
            </span>
          ),
          action: () => setActiveBreakdown(name),
        }))
      : [];
  }, [possibleBreakdowns, activeBreakdown]);

  useEffect(() => {
    document.documentElement.style.setProperty("--overlay-panel-opacity", 1);
    // Note: Some elements (like popups and popovers) are "overlays", which we
    // want to have fade out while a swipe happens and fade back in when the
    // slide is done moving.
    //
    // To implement this, we set the opacity of all overlays to 1 initially,
    // and then set it to 0 when a swipe starts and to 1 when the slide is done
    // moving.

    calculateTransitionalScene();
  }, [activeScene, calculateTransitionalScene]);

  const onSetTranslate = useCallback(() => {
    const displacement = getSlideDisplacement();

    // Note: This isn't abrupt because there's also a CSS transition smoothing
    // the opacity transition.
    document.documentElement.style.setProperty(
      "--overlay-panel-opacity",
      displacement === 0 ? 1 : 0,
    );

    calculateTransitionalScene();
  }, [getSlideDisplacement, calculateTransitionalScene]);

  const advanceSwiper = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slideNext();
    }
  }, [mainSwiper]);

  return (
    <FadeIn>
      {!popupState?.popup && (
        <>
          <TopButtonsShow
            onClick={toggleVisibility}
            $buttonsAreVisible={isVisibleTopButtons}
          />
          <BackButtonWrapper>
            {isVisibleTopButtons && (
              <TopBarButtonWrapper>
                <BackButton onClick={handleBackButton} />
              </TopBarButtonWrapper>
            )}
            {!isIOS && isVisibleTopButtons && (
              <FullscreenButton
                className="fullscreen-button"
                onClick={toggleFullscreen}
              >
                <img
                  src={isFullscreen ? IconCompress : IconExpand}
                  width="30"
                  height="30"
                />
              </FullscreenButton>
            )}
            <div onClick={toggleVisibility} style={{ width: "100%" }}></div>
            {!!numberOfCards && (
              <TopBarButtonWrapper
                style={{
                  display: isVisibleTopButtons ? "block" : "none",
                }}
              >
                <NumberIcon
                  number={numberOfCards}
                  onClick={() =>
                    setPopupState({
                      popup: "deck",
                      breakdown:
                        getInt(activeScene) >= 1 &&
                        getInt(activeScene) <= subtitles.length
                          ? subtitles[getInt(activeScene) - 1]
                          : {},
                    })
                  }
                  style={{ padding: "1rem" }}
                  icon={"cards"}
                />
              </TopBarButtonWrapper>
            )}
            {!isVisibleTopButtons && (
              <div
                style={{
                  cursor: "pointer",
                  pointerEvents: "none",
                  width: "calc(5% + 4.5rem + 6px)",
                }}
              ></div>
            )}
          </BackButtonWrapper>
        </>
      )}
      <Swiper
        direction="horizontal"
        loop={false}
        spaceBetween={30}
        centeredSlides
        keyboard={{ enabled: false }}
        threshold={50}
        onSetTranslate={onSetTranslate}
        onSwiper={(swiper) => {
          mainSwiper.current = swiper;
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
        onSlideChangeTransitionEnd={(swiper) => {
          setLastSoloScene(swiper.activeIndex);
        }}
      >
        {fragments.map((f, i) => (
          <SwiperSlide key={i} virtualIndex={i}>
            {Math.abs(i - activeScene) <= SCENES_RENDERED_ON_EITHER_SIDE && (
              <Scene
                videoElementRef={
                  i % 2 ? oddSlideVideoElementRef : evenSlideVideoElementRef
                }
                fragment={f}
                subtitle={
                  getInt(f) >= 1 && getInt(f) <= subtitles.length
                    ? subtitles[getInt(f) - 1]
                    : {}
                }
                episode={episode}
                index={i}
                posterUrl={posterUrls?.[i]}
                isActiveScene={i === activeScene}
                isLastSoloScene={i === lastSoloScene}
                isTransitionalScene={i === transitionalScene}
                indicateReady={indicateReady}
                popoverState={popoverState}
                setPopoverState={setPopoverState}
                popupState={popupState}
                popoverHasScrollbars={popoverHasScrollbars}
                setPopoverHasScrollbars={setPopoverHasScrollbars}
                setPopupState={setPopupState}
                subtitlesVisible={subtitlesVisible}
                setSubtitlesVisible={setSubtitlesVisible}
                createCardCallback={createCardCallback}
                breakdownMenuOptions={breakdownSwitchOptions}
                getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
                setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
                advanceSwiper={advanceSwiper}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </FadeIn>
  );
}
