import React from "react";
import styled from "styled-components";

const Dots = styled.div`
  display: inline-block;
  border: none;
  border-top: 1px dashed rgba(127, 127, 127, 0.6);
  width: 100%;
`;

const BreakContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(127, 127, 127);
`;

const BreakText = styled.p`
  padding: 0.4rem 0.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export default function DottedBreak({ children }) {
  return (
    <BreakContainer>
      <Dots />
      {!!children && (
        <>
          <BreakText>{children}</BreakText>
          <Dots />
        </>
      )}
    </BreakContainer>
  );
}
