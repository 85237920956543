import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../common.js";
import { SessionContext } from "./ContextProvider.js";
import Loading from "./Loading.js";

function OAuthLogin() {
  const navigate = useNavigate();
  const [, setSession] = useContext(SessionContext);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    getRequest("oauth2session", { code }, (response) => {
      if (!response || response.error) {
        console.error("Network response was not ok:", response?.error);
        navigate("/");
        return;
      }
      setSession(response);
      navigate("/");
    });
  }, [1]);

  return <Loading />;
}

export default OAuthLogin;
