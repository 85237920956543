function findCharacterSubstringRange(characters, original) {
  for (let start = 0; start < characters.length; start++) {
    for (let end = start + 1; end <= characters.length; end++) {
      const candidate = characters.slice(start, end);
      if (candidate.map((c) => c.original).join("") == original) {
        return [start, end];
      }
    }
  }
  return null;
}

export function findCharacterSubstring(characters, original) {
  let range = findCharacterSubstringRange(characters, original);
  if (range === null) return null;
  let [start, end] = range;
  return characters.slice(start, end);
}

export function highlightCharacterSubstring(characters, original) {
  let range = findCharacterSubstringRange(characters, original);
  let [start, end] = range ?? [0, 0];
  return characters.map((c, i) => ({ ...c, highlight: i >= start && i < end }));
}
