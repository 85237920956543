import React, { useState } from "react";
import styled from "styled-components";
import BreakdownButton from "../atoms/BreakdownButton";
import { timeMicrosOf, postRequest, writeClipboard } from "../../common";
import AutoGrowTextArea from "../atoms/AutoGrowTextArea";
import { toast } from "react-toastify";
import { isDesktop } from "react-device-detect";

const NonBoldText = styled.p`
  font-weight: normal;
`;

const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Limit the height of the auto grow text area
const TextAreaWrapper = styled.div`
  max-height: 50vh;
  overflow: auto;
  width: 100%;
`;

export default function FeedbackPopup({ episode, closePopup, breakdown }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  const sendFeedback = async () => {
    setLoading(true);
    setError("");
    try {
      await postRequest(
        "/api/feedback",
        {
          episodeId: episode.id,
          breakdownIndex: breakdown.index,
          timeMicros: timeMicrosOf(breakdown),
          sentence: breakdown.original,
          breakdown: JSON.stringify(breakdown),
          comment: feedback,
        },
        (res) => {
          try {
            if (res.success) {
              closePopup();
              toast("✉️ Email sent! Thanks for your feedback!");
            } else if (res.error) {
              throw new Error(res.error);
            } else {
              throw new Error("Unknown error");
            }
          } catch (err) {
            setError(err.message ? err.message : err);
          }
        },
      );
    } catch (err) {
      setError(err.message ? err.message : err);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkClick = () => {
    writeClipboard(window.location.href);
    toast("Link copied to clipboard");
  };

  return (
    <FeedbackWrapper>
      <NonBoldText $centerText="false">
        Hello! If you have any feedback about the LingoFlix website or the way
        the current subtitle has been translated or broken down, please let us
        know by writing it here. Cheers!
      </NonBoldText>
      <small onClick={handleLinkClick}>
        <b>Link:</b> &nbsp;
        {window.location.href}
      </small>
      <TextAreaWrapper>
        <AutoGrowTextArea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          rows={2}
          focus={isDesktop}
        />
      </TextAreaWrapper>
      {loading && <p>Sending...</p>}
      {!loading && (
        <BreakdownButton className="breakdown-button" onClick={sendFeedback}>
          Send
        </BreakdownButton>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </FeedbackWrapper>
  );
}
