import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipContent = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 4px;
  z-index: 1000;
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  max-width: 60vw;
  width: max-content;
`;

const TooltipCaret = styled.span`
  position: absolute;
  top: 50%;
  left: -5px; /* Move caret to the left side of the TooltipContent */
  margin-top: -5px; /* Center caret vertically */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 5px 0; /* Create a left-pointing caret */
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
`;

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const childRef = useRef(null);
  const contentRef = useRef(null);
  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const setTopAndLeft = () => {
    if (childRef.current && contentRef.current) {
      setTop(
        childRef.current.getBoundingClientRect().height / 2 -
          contentRef.current.getBoundingClientRect().height / 2,
      );
      setLeft(childRef.current.getBoundingClientRect().width);
    }
  };

  useEffect(setTopAndLeft, [isVisible]);

  return (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <div ref={childRef}>{children}</div>
      {
        <TooltipContent
          ref={contentRef}
          style={{
            display: isVisible ? "block" : "none",
            position: "absolute",
          }}
          $top={top}
          $left={left}
        >
          {text}
          <TooltipCaret />
        </TooltipContent>
      }
    </TooltipContainer>
  );
};

export default Tooltip;
