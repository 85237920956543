import styled, { css } from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ $gap }) => ($gap !== undefined ? $gap : "1rem")};
  }

  ${({ baseline, $center, $justifyCenter, $justifyEvenly, $fullWidth }) => css`
    ${baseline && "align-items: baseline;"}
    ${$center && "align-items: center;"}
    ${$justifyCenter && "justify-content: center;"}
    ${$justifyEvenly && "justify-content: space-evenly;"}
    ${$fullWidth && "width: 100%;"}
  `}
`;

export default Column;
