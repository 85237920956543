import styled, { css } from "styled-components";
import { colors } from "../constants.js";

export default styled.button`
  &.breakdown-button {
    position: relative;
    border: 1px solid rgba(151, 151, 151, 0.8);
    outline: none;
    background: none;
    color: white;
    ${({ $greyOut }) =>
      $greyOut
        ? css`
            filter: brightness(0.5) grayscale(0.8);
          `
        : css`
            cursor: pointer;
            &:focus,
            &:hover {
              outline: none;
              border-color: ${colors.primary};
            }
          `}
    border-radius: ${({ $borderRadius }) =>
      $borderRadius ? $borderRadius : "10px"};
    text-transform: ${({ $noCapitalize }) =>
      $noCapitalize ? "none" : "uppercase"};
    font-weight: 600;
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "1.125rem")};
    padding: ${({ $padding }) => ($padding ? $padding : "0.75rem 1rem")};
    transition: 0.2s border;
    letter-spacing: 1px;
    box-sizing: border-box;
    pointer-events: all;

    @media (max-width: 768px) {
      font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "1rem")};
    }

    ${({ $primary }) =>
      $primary &&
      css`
        background: ${colors.primary};
        border: none;
      `}

    ${({ $fitContent }) =>
      $fitContent &&
      css`
        height: fit-content;
        width: fit-content;
      `}

    &:disabled {
      background: none;
      border-color: rgba(151, 151, 151, 0.8);
      color: rgba(255, 255, 255, 0.5);
      cursor: not-allowed;
    }

    ${({ $noWrap }) =>
      $noWrap &&
      css`
        white-space: nowrap;
      `}
  }
`;
