import styled, { css } from "styled-components";
import { colors } from "../constants.js";

export default styled.input.attrs({ className: "swiper-no-swiping" })`
  background: ${colors.textBackground};
  box-shadow: inset 1 1 0 ${colors.textShadow};
  color: white;
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid transparent;
  font-size: 2em;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  ${({ $centerText }) =>
    $centerText &&
    css`
      text-align: center;
    `}

  &:disabled {
    background: #343a40; /* Darker background */
    color: #adb5bd; /* Lighter text color for contrast */
    border-color: #495057; /* Darker border color */
    cursor: not-allowed;
  }
`;
