import React from "react";
import styled from "styled-components";
import Row from "../../layout/Row";
import Column from "../../layout/Column";

const CloseColumn = styled.div`
  display: flex;
  flex-direction: column;

  & .muted {
    text-align: center;
    color: rgba(140, 140, 140, 0.85);
  }

  & .lit {
    font-weight: bold;
    color: #ffffff;
  }
`;

function Sentence({
  sentenceElements,
  wordOriginal,
  sentenceTranslation,
  wordTranslation,
  doWrap = false,
}) {
  const highlightWord = (text, word) => {
    if (!word) return text;
    const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedWord})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="lit">
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <CloseColumn>
      {sentenceElements.map((spans, sentenceIndex) => (
        <Row $gap="0.5rem" key={sentenceIndex} $justifyCenter $doWrap={doWrap}>
          {spans.map((c, spanIndex) => (
            <Column
              key={spanIndex}
              $gap={0}
              className={c.original === wordOriginal ? "lit" : "muted"}
            >
              <p>{c.pronunciation || " "}</p>
              <h1>{c.original}</h1>
            </Column>
          ))}
        </Row>
      ))}
      {sentenceTranslation && (
        <div className="muted">
          <p>{highlightWord(sentenceTranslation, wordTranslation)}</p>
        </div>
      )}
    </CloseColumn>
  );
}

export default Sentence;
