import styled from "styled-components";

export default styled.ul`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 2px 0 0 0;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};

  & > * {
    margin-bottom: ${({ $wrap }) => ($wrap ? "20px" : "0")};
  }
`;
