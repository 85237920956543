import React from "react";
import styled from "styled-components";
import Card from "./atoms/Card.js";
import IconLock from "../assets/icons/lock.svg";
import IconLockOpen from "../assets/icons/lock-open.svg";
import IconPeopleArrows from "../assets/icons/people-arrows.svg";
import IconDeck from "../assets/icons/cards-deck.svg";

const ShareStatusIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
`;

const DeckIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 40px;
  width: 20px;
  height: 20px;
`;

const CardContainer = styled.div`
  position: relative;
`;

function AnkiCard({
  children,
  isCreatedByCurrentUser,
  getCardShared,
  getCardSelected,
  setCardSelected,
  cardHtml,
  isInDeck,
}) {
  const toggleSelection = () => {
    setCardSelected(!getCardSelected());
  };
  const shareStatusIcon = (
    <ShareStatusIcon
      src={
        isCreatedByCurrentUser()
          ? getCardShared()
            ? IconLockOpen
            : IconLock
          : IconPeopleArrows
      }
    />
  );

  const deckIcon = isInDeck ? <DeckIcon src={IconDeck} /> : null;

  return (
    <CardContainer>
      {shareStatusIcon}
      {deckIcon}
      <Card $toggled={getCardSelected()} onClick={toggleSelection}>
        {cardHtml && (
          <div
            className="back"
            dangerouslySetInnerHTML={{ __html: cardHtml }}
          ></div>
        )}
        {children}
      </Card>
    </CardContainer>
  );
}

export default AnkiCard;
