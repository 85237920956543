import { React, useCallback, useMemo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import AnkiCard from "./AnkiCard.js";
import BackButton from "./atoms/BackButton.js";
import Header from "./layout/Header.js";
import Loading from "./Loading.js";
import { exportAnkiCards } from "./atoms/AnkiExportButton.js";
import {
  getRequest,
  microsToTimestamp,
  writeClipboard,
  postRequest,
  deleteRequest,
  logEvent,
} from "../common.js";
import { colors } from "./constants.js";
import AnkiEditor from "./breakdown/anki/AnkiEditor.js";
import { toast } from "react-toastify";
import IconDeck from "../assets/icons/cards-deck.svg";
import IconLock from "../assets/icons/lock.svg";
import IconLockOpen from "../assets/icons/lock-open.svg";
import IconLink from "../assets/icons/link.svg";
import IconTrashCan from "../assets/icons/trash-can.svg";
import Dropdown from "./breakdown/Dropdown.js";
import Row from "./layout/Row.js";

const TitleHeader = styled.div`
  & span {
    color: rgba(140, 140, 140, 0.85);
  }
`;
const Rows = styled.div`
  display: flex;
  overflow: auto visible;
  & > * + * {
    margin-left: ${({ $gap }) => ($gap !== undefined ? $gap : "1rem")};
  }
`;
const deckIcon = (
  <img
    src={IconDeck}
    width="20"
    height="20"
    alt="add to deck"
    className="icon"
  />
);

const ActionButton = styled.button`
  margin-right: 1rem;
  margin-bottom: 1rem;

  outline: none;
  background: none;
  ${({ $enabled }) =>
    $enabled
      ? css`
          cursor: pointer;
          border: 1px solid rgba(151, 151, 151, 0.8);
          color: white;
        `
      : css`
          cursor: not-allowed;
          border: 1px solid rgba(50, 50, 50, 0.8);
          color: gray;
        `}

  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.125rem;
  padding: 0.5rem;
  transition: 0.2s border;
  letter-spacing: 1px;
  box-sizing: border-box;
  pointer-events: all;

  &:focus,
  ${({ $primary }) =>
    $primary &&
    css`
      background: ${colors.primary};
      border: none;
    `}
  }
`;

const EditorWrapper = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
`;

export async function fetchSceneUrl(episodeId, micros) {
  const episode = await getRequest(`/api/episode/by_id/${episodeId}`);
  const timestamp = microsToTimestamp(micros);
  return `/watch/${episode.show.url}/${episode.url}?time=${timestamp}`;
}

export default function AnkiCardView({ breakdownIndex, movieId }) {
  const { cardUuids } = useParams();
  const [cards, setCards] = useState([]);
  const [editedCard, setEditedCard] = useState(null);
  const navigate = useNavigate();

  const isPersonalDeck = !cardUuids && !movieId && !breakdownIndex;

  const isSceneCard = movieId != null && breakdownIndex != null;

  useEffect(() => {
    if (!isSceneCard) {
      document.title = "LingoFlix - Anki Deck";
    }
  }, [movieId, breakdownIndex]);

  const getSelectedCards = useCallback(() => {
    let selections = {};
    if (cards === null) return selections;
    for (let c of cards) {
      selections[c.id] = c.selected;
    }
    return selections;
  }, [cards]);

  const setSelectedCards = useCallback(
    (selectedCards) => {
      setCards((oldCards) =>
        oldCards.map((c) => ({
          ...c,
          selected: selectedCards[c.id],
        })),
      );
    },
    [setCards],
  );

  const updateCards = useCallback(() => {
    const processRows = (rows) => {
      // Preserve selection information across updates:
      let selectedCardIds = getSelectedCards();
      setCards(
        rows.map((row) => ({
          ...row,
          selected: selectedCardIds[row.id] || false,
          data: row.data,
        })),
      );
    };

    if (cardUuids) {
      postRequest(
        "/api/anki/get_cards",
        { cardIds: [], cardUuids: cardUuids.split(",") },
        processRows,
      );
    } else {
      const url = isSceneCard
        ? `/api/anki/scene_cards/${movieId}/${breakdownIndex}`
        : "/api/anki/user_cards";

      getRequest(url, {}, processRows);
    }
  }, [cardUuids, isSceneCard, movieId, breakdownIndex]);

  const addToDeck = useCallback(
    async (card) => {
      logEvent(`add-card-to-deck-with-id-${card}`);
      await postRequest("/api/anki/add_card_to_deck", { card }, updateCards);
    },
    [updateCards],
  );

  const removeFromDeck = useCallback(
    async (card) => {
      logEvent(`remove-card-from-deck-with-id-${card}`);
      await deleteRequest(
        "/api/anki/remove_card_from_deck",
        { card },
        updateCards,
      );
    },
    [updateCards],
  );

  const isCreatedByCurrentUser = useCallback(
    (card) => {
      return cards.find((c) => c.id === card).isCreatedByCurrentUser;
    },
    [cards],
  );

  const getCardShared = useCallback(
    (card) => {
      return cards.find((c) => c.id === card).shared;
    },
    [cards],
  );

  const setCardShared = useCallback(
    async (card, isShared) => {
      return postRequest("/api/anki/set_card_shared", {
        card,
        isShared,
      }).then(() => {
        setCards((cards) =>
          cards.map((c) => {
            if (c.id === card) {
              c.shared = isShared;
            }
            return c;
          }),
        );
      });
    },
    [cards],
  );

  const getCardUuid = useCallback(
    (card) => {
      return cards.find((c) => c.id === card).uuid;
    },
    [cards],
  );

  useEffect(updateCards, [updateCards]);

  function handleBackButton() {
    if (editedCard) {
      setEditedCard(null);
    } else {
      navigate(isSceneCard ? `/movie/${movieId}` : "/");
    }
  }

  const headerLeftContent = <BackButton onClick={handleBackButton} />;

  const headerCenterContent = (
    <TitleHeader>
      <h1>
        {editedCard ? "Editing Card" : "Your Anki Deck"}{" "}
        {!editedCard && cards.length ? <span>({cards.length})</span> : null}
      </h1>
    </TitleHeader>
  );

  const createToastOnCompletion = useCallback(async (promises, message) => {
    await Promise.all(promises);
    toast(message);
  }, []);

  const cardIsInDeck = useCallback(
    (cardId) => cards.find((c) => c.id === cardId).isInDeck,
    [cards],
  );

  const selectedCardIds = useMemo(() => {
    if (!cards) return [];
    return cards.filter((c) => c.selected).map((c) => c.id);
  }, [cards]);

  const selectedCardsCreatedByCurrentUser = useMemo(
    () => selectedCardIds.every(isCreatedByCurrentUser),
    [isCreatedByCurrentUser, selectedCardIds],
  );

  // Selection logic (to be forwarded to the AnkiCard component):
  const getCardSelected = useCallback(
    (cardId) => getSelectedCards()[cardId],
    [getSelectedCards],
  );

  const setCardSelected = useCallback(
    (cardId, selected) => {
      let selection = getSelectedCards();
      setSelectedCards({ ...selection, [cardId]: selected });
    },
    [getSelectedCards, setSelectedCards],
  );

  const allCardsSelected = useMemo(
    () => selectedCardIds.length === cards.length,
    [selectedCardIds, cards],
  );

  // Select all button:
  const selectAll = useCallback(() => {
    let allCards = cards.reduce((acc, c) => ({ ...acc, [c.id]: true }), {});
    setSelectedCards(allCards);
  }, [cards, setSelectedCards]);
  const selectAllButton = {
    label: "Select All",
    action: selectAll,
    enabled: cards.length > 0 && !allCardsSelected,
  };

  // Deselect all button:
  const deselectAll = useCallback(
    () => setSelectedCards({}),
    [setSelectedCards],
  );
  const deselectAllButton = {
    label: "Deselect All",
    action: deselectAll,
    enabled: cards.length > 0 && allCardsSelected,
  };

  // EditButton
  const canEdit = selectedCardIds.length === 1;
  const editCard = useCallback(() => {
    const cardId = selectedCardIds[0];
    try {
      return postRequest(
        "/api/anki/get_cards",
        { cardIds: [cardId], cardUuids: [] },
        (res) => {
          if (!res?.length) {
            toast("Could not find card to edit.");
            return;
          }
          setEditedCard(res[0]);
        },
      );
    } catch (e) {
      console.error(e);
      toast("Failed to fetch card data.");
    }
  }, [selectedCardIds, setEditedCard]);

  const editButton = {
    label: "Edit",
    action: editCard,
    enabled: canEdit,
  };

  // Share button
  const shareSelectedCards = useCallback(
    () =>
      createToastOnCompletion(
        selectedCardIds.map((cardId) => setCardShared(cardId, true)),
        <span>
          <img src={IconLockOpen} width="16" height="16" />
          {" Cards shared"}
        </span>,
      ),
    [selectedCardIds, setCardShared],
  );
  const canShare = useMemo(
    () =>
      selectedCardsCreatedByCurrentUser &&
      selectedCardIds.some((id) => !getCardShared(id)),
    [selectedCardsCreatedByCurrentUser, selectedCardIds, getCardShared],
  );
  const shareButton = {
    label: "Share",
    action: shareSelectedCards,
    enabled: canShare,
  };

  // Unshare button
  const unshareSelectedCards = useCallback(
    () =>
      createToastOnCompletion(
        selectedCardIds.map((cardId) => setCardShared(cardId, false)),
        <span>
          <img src={IconLock} width="16" height="16" />
          {" Cards unshared"}
        </span>,
      ),
    [selectedCardIds, setCardShared],
  );
  const canUnshare = useMemo(
    () =>
      selectedCardsCreatedByCurrentUser &&
      selectedCardIds.some((id) => getCardShared(id)),
    [selectedCardsCreatedByCurrentUser, selectedCardIds, getCardShared],
  );
  const unshareButton = {
    label: "Unshare",
    action: unshareSelectedCards,
    enabled: canUnshare,
  };

  // Copy link button
  const copyLinkToCards = useCallback(() => {
    let uuids = selectedCardIds.map((cardId) => getCardUuid(cardId)).join(",");
    let url = `${window.location.origin}/shared/anki-card-view/${uuids}`;
    writeClipboard(url);
    toast(
      <span>
        <img src={IconLink} width="16" height="16" /> Link copied to clipboard{" "}
      </span>,
    );
  }, [selectedCardIds, getCardUuid]);
  const canCopyLinkToCards = useMemo(
    () =>
      selectedCardIds.length > 0 &&
      selectedCardIds.every((id) => getCardShared(id)),
    [selectedCardIds, getCardShared],
  );
  const copyLinkButton = {
    label: (
      <span>
        Copy <img src={IconLink} width="16" height="16" />
      </span>
    ),
    action: copyLinkToCards,
    enabled: canCopyLinkToCards,
  };

  // Add to deck button
  const addSelectedCards = useCallback(
    () =>
      createToastOnCompletion(
        selectedCardIds.map(addToDeck),
        <span>
          <img src={IconDeck} width="16" height="16" /> Cards added to deck
        </span>,
      ),
    [selectedCardIds, addToDeck],
  );
  const canAddSelectedCards = useMemo(
    () =>
      selectedCardIds.length > 0 &&
      selectedCardIds.every((id) => !cardIsInDeck(id)),
    [selectedCardIds, cardIsInDeck],
  );
  const addToDeckButton = {
    label: <>Add {deckIcon}</>,
    action: addSelectedCards,
    enabled: canAddSelectedCards,
  };

  // Remove from deck button:
  const removeSelectedCards = useCallback(() => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (confirm(`Remove ${selectedCardIds.length} card(s) from your deck?`)) {
      createToastOnCompletion(
        selectedCardIds.map(removeFromDeck),
        <span>
          <img src={IconTrashCan} width="16" height="16" />{" "}
          {selectedCardIds.length} cards removed from deck
        </span>,
      );
    }
  }, [selectedCardIds, removeFromDeck]);
  const canRemoveSelectedCards = useMemo(
    () =>
      selectedCardIds.length > 0 &&
      selectedCardIds.every((id) => cardIsInDeck(id)),
    [selectedCardIds, cardIsInDeck],
  );
  const removeFromDeckButton = {
    label: <>Remove {deckIcon}</>,
    action: removeSelectedCards,
    enabled: canRemoveSelectedCards,
  };

  const canExport = useMemo(
    () => !isSceneCard && selectedCardIds.length > 0,
    [!isSceneCard, selectedCardIds],
  );
  const exportButton = {
    label: "Export",
    action: () => exportAnkiCards(selectedCardIds),
    enabled: canExport,
  };

  const openScene = async () => {
    const card = cards.find((c) => c.id === selectedCardIds[0]);
    const url = await fetchSceneUrl(card.episode, card.timeMicros);
    navigate(url);
  };
  const openSceneButton = {
    label: "Open Scene",
    action: () => openScene(),
    enabled: selectedCardIds.length === 1,
  };

  const exportAll = () => {
    const allCardIds = cards.map((card) => card.id);
    exportAnkiCards(allCardIds);
  };
  const exportAllButton = {
    label: "Export All",
    action: exportAll,
    enabled: cards.length > 0,
  };

  const buttons = [exportAllButton, selectAllButton, deselectAllButton];

  const dropdownOptions = [
    editButton,
    shareButton,
    unshareButton,
    addToDeckButton,
    removeFromDeckButton,
    copyLinkButton,
    exportButton,
    openSceneButton,
  ];
  const closeEditPopup = () => {
    setEditedCard(null);
    updateCards();
  };

  if (!cards) {
    return <Loading />;
  }

  return (
    <>
      {isPersonalDeck && (
        <div>
          <Header $left={headerLeftContent} $center={headerCenterContent} />
        </div>
      )}
      <div>
        {editedCard !== null ? (
          <EditorWrapper>
            {isPersonalDeck && (
              <Header
                $left={<BackButton onClick={() => setEditedCard(null)} />}
                $center={
                  <h3>
                    Editing Card for &quot;{editedCard.data.eng_word}&quot;
                  </h3>
                }
              />
            )}
            <AnkiEditor card={editedCard} closeCallback={closeEditPopup} />
          </EditorWrapper>
        ) : (
          <>
            <Row>
              <Rows $gap="0.5rem">
                {buttons.map((button, i) => {
                  const handleClick = () => {
                    if (button.enabled && button.action) button.action();
                  };
                  return (
                    <ActionButton
                      key={i}
                      onClick={handleClick}
                      $enabled={!!button.enabled}
                    >
                      {button.label}
                    </ActionButton>
                  );
                })}
              </Rows>

              <Dropdown direction="right" options={dropdownOptions} />
            </Row>

            {cards.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                You have no cards in this deck yet.
              </p>
            )}

            <Rows>
              {cards.map((c) => (
                <AnkiCard
                  key={c.id}
                  id={c.id}
                  word={c.data.word}
                  engWord={c.data.eng_word}
                  cardFront={c.data.cardFront}
                  cardBack={c.data.cardBack}
                  kanjis={c.data.kanjis}
                  wordRubyBreakdown={c.data.wordRubyBreakdown}
                  rank={c.data.rank}
                  engFullsub={c.data.eng_fullsub}
                  sentence={c.data.sentence}
                  customFields={c.data.custom_fields}
                  videoFrameSrc={"/video/" + c.data.video_frame_src}
                  isInDeck={c.isInDeck}
                  isCreatedByCurrentUser={() => isCreatedByCurrentUser(c.id)}
                  getCardSelected={() => getCardSelected(c.id)}
                  setCardSelected={(selected) =>
                    setCardSelected(c.id, selected)
                  }
                  getCardShared={() => getCardShared(c.id)}
                  cardHtml={c.backHtml}
                />
              ))}
            </Rows>
          </>
        )}
      </div>
    </>
  );
}
