import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../common.js";
import Page from "../layout/Page.js";
import CollectionRow from "../layout/CollectionRow.js";
import VideosList from "./VideosList.js";
import Header from "../layout/Header.js";
import BackButton from "../atoms/BackButton.js";
import Loading from "../Loading.js";

function episodeLabels(title) {
  const match = title.match(/S(\d+)E(\d+)/);
  if (match) {
    return { season: parseInt(match[1], 10), episode: parseInt(match[2], 10) };
  }
  const seasonMatch = title.match(/(S|Season)\s*(\d+)/i);
  const season = seasonMatch ? parseInt(seasonMatch[2], 10) : -1;
  const episodeMatch = title.match(/(E|Episode)\s*(\d+)/i);
  const episode = episodeMatch ? parseInt(episodeMatch[2], 10) : -1;
  return { season, episode };
}

export default function ShowPage() {
  const { show: showUrl } = useParams();
  const [show, setShow] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getRequest(`/api/episode/${showUrl}`)
      .then((show) => ({
        ...show,
        episodes: show.episodes
          .map((episode) => ({
            ...episodeLabels(episode.title),
            ...episode,
            showUrl: show.url,
          }))
          .sort((a, b) => {
            if (a.season !== b.season) {
              return a.season - b.season;
            }
            if (a.episode !== b.episode) {
              return a.episode - b.episode;
            }
            if (a.title !== b.title) {
              return a.title.localeCompare(b.title);
            }
            return 0;
          }),
      }))
      .then(setShow)
      .catch((e) => {
        console.error("ERROR fetching shows", e);
        setError("Error fetching shows");
      });
  }, [showUrl]);

  useEffect(() => {
    document.title = `${show?.title ?? "Unknown Show"} - LingoFlix`;
  }, [show]);

  if (show === null) {
    return <Loading />;
  }
  if (error) {
    return (
      <div>
        <h1>Error Loading Shows: </h1>
        <pre style={{ color: "red" }}>{error}</pre>
      </div>
    );
  }

  if (show?.episodes?.length === 0) {
    return <h1>No episodes</h1>;
  }

  const episodesBySeason = {};
  show.episodes.forEach((episode) => {
    episodesBySeason[episode.season] = episodesBySeason[episode.season] || [];
    episodesBySeason[episode.season].push(episode);
  });

  const seasons = Object.keys(episodesBySeason)
    .map((season) => parseInt(season, 10))
    .sort();

  const headerLeftContent = (
    <BackButton
      onClick={() => {
        navigate("/");
      }}
    />
  );
  const headerCenterContent = <h1>{show.title}</h1>;

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      {seasons.map((season) => (
        <>
          {season !== -1 && <h2>Season {season}</h2>}
          {season === -1 && seasons.length > 1 && <h2>Miscellaneous</h2>}
          <CollectionRow $wrap key={season}>
            <VideosList tiles={episodesBySeason[season]} wrap />
          </CollectionRow>
        </>
      ))}
    </Page>
  );
}
