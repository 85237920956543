import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../common.js";
import Tiles from "../layout/Tiles.js";
import Tile from "../layout/Tile.js";
import TileProgressBar from "../layout/TileProgressBar.js";
import { ProgressContext } from "../ContextProvider";

function VideosList({ tiles, wrap }) {
  const [progress] = useContext(ProgressContext);
  const navigate = useNavigate();

  if (!tiles) {
    return <h1>No videos</h1>;
  }
  return (
    <Tiles $wrap={wrap}>
      {tiles.map((video, i) => {
        const videoProgress = progress && progress[video.id];
        return (
          <Tile
            key={i}
            $backgroundImage={`/video/${video.path}/.middle.png`}
            onClick={() => {
              if (video.showUrl) {
                navigate(`/watch/${video.showUrl}/${video.url}`);
              } else {
                navigate(`/browse/${video.url}`);
              }
              logEvent("clicked-video-list-show");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <span style={{ alignSelf: "flex-start" }}>
                {video.topTitle ? video.topTitle : ""}
              </span>
              {video.title && <span>{video.title}</span>}
            </div>
            {videoProgress &&
              video.showUrl &&
              Number.isFinite(videoProgress) &&
              videoProgress >= 0.0 &&
              videoProgress <= 1.0 && (
                <TileProgressBar>
                  <span style={{ width: `${videoProgress * 100}%` }} />
                </TileProgressBar>
              )}
          </Tile>
        );
      })}
    </Tiles>
  );
}

export default VideosList;
