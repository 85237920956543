import React, { useContext } from "react";
import { createGlobalStyle } from "styled-components";
import "./css/common.css";
import "./css/fonts.css";
import Router from "./Router.js";
import LoginRouter from "./LoginRouter.js";
import { SessionContext, ContextProvider } from "./ContextProvider.js";
import Loading from "./Loading.js";
import Tos from "./Tos.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Disable iPad/mobile overscroll and pinch-to-zoom, for a PWA feel
const GlobalStyle = createGlobalStyle`
body, html {
  overscroll-behavior: none;
	touch-action: pan-x pan-y;
}

&::-webkit-scrollbar {
	width: 10px;
}

&::-webkit-scrollbar-track {
	color: transparent;
}

&::-webkit-scrollbar-thumb {
	background: transparent;
}

&::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 5px;
}

&::-webkit-scrollbar-thumb {
	background: #444444;
	border-radius: 5px;
}
`;

function AppInner() {
  const [session] = useContext(SessionContext);
  if (session === null) {
    return <Loading />;
  }

  if (session.sub) {
    if (!session.tos) {
      return <Tos />;
    }
    return <Router />;
  }

  return <LoginRouter />;
}

/// Wrap AppInner with all providers and middlewares we need
export default function App() {
  return (
    <ContextProvider>
      <GlobalStyle />
      <AppInner />
      <ToastContainer theme="dark" />
    </ContextProvider>
  );
}
