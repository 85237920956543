import React from "react";
import styled from "styled-components";
import BackIcon from "../../assets/icons/back.svg";
import Row from "../layout/Row.js";
import { colors } from "../constants.js";
import { logEvent } from "../../common.js";

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 2rem 2rem;
  margin: -2rem -2rem;
  outline: none;
  cursor: pointer;
  pointer-events: all;

  & * {
    transition: 0.2s all;
  }

  &:hover,
  &:focus {
    svg g {
      stroke: ${colors.primary};
    }
    & * {
      color: ${colors.primary};
    }
  }
`;

const P = styled.p`
  margin: 0 0 0 1rem;
`;

export default function BackButton({ onClick, text }) {
  return (
    <Button
      onClick={() => {
        onClick();
        logEvent("clicked-backbutton");
      }}
    >
      <Row $gap="1rem" $center>
        <img src={BackIcon} alt="Back" />
        <P>{text}</P>
      </Row>
    </Button>
  );
}
