import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  &::placeholder {
    color: #aaa;
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #444;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #222;
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #444;
  }
`;

const AutoCompleteDropdown = ({ options, onSelect }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const normalize = (x) => x.toLowerCase().normalize("NFC");
    const sortKey = (keyword) => [
      normalize(keyword).startsWith(normalize(value)) ? 0 : 1,
      normalize(keyword),
    ];

    const filtered = options
      .filter((option) =>
        option.some((keyword) => normalize(keyword).includes(normalize(value))),
      )
      .sort((a, b) => {
        const ka = sortKey(a[0]),
          kb = sortKey(b[0]);
        if (ka[0] < kb[0]) return -1;
        if (ka[0] > kb[0]) return +1;
        if (ka[1] < kb[1]) return -1;
        if (ka[1] > kb[1]) return +1;
        return 0;
      });
    setFilteredOptions(filtered);
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleSelectOption = (option) => {
    setInputValue(""); // Clear the input field
    setFilteredOptions(options); // Reset filteredOptions to original options
    setIsOpen(false);
    onSelect(option[0]);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        placeholder="Type to search..."
      />
      {isOpen && (
        <Dropdown>
          {filteredOptions.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleSelectOption(option)}
            >
              {option[0]}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default AutoCompleteDropdown;
