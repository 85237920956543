import React from "react";
import AutoCompleteDropdown from "./AutoCompleteDropdown";

const languages = [
  { code: "ab", name: "Abkhazian", keywords: ["Аҧсуа"] },
  { code: "aa", name: "Afar", keywords: ["Afaraf"] },
  { code: "af", name: "Afrikaans", keywords: [] },
  { code: "ak", name: "Akan", keywords: [] },
  { code: "sq", name: "Albanian", keywords: ["Shqip"] },
  { code: "am", name: "Amharic", keywords: ["አማርኛ"] },
  { code: "ar", name: "Arabic", keywords: ["العربية"] },
  { code: "an", name: "Aragonese", keywords: ["Aragonés"] },
  { code: "hy", name: "Armenian", keywords: ["Հայերեն"] },
  { code: "as", name: "Assamese", keywords: ["অসমীয়া"] },
  { code: "av", name: "Avaric", keywords: ["авар мацӀ"] },
  { code: "ae", name: "Avestan", keywords: [] },
  { code: "ay", name: "Aymara", keywords: ["aymar aru"] },
  { code: "az", name: "Azerbaijani", keywords: ["azərbaycan dili"] },
  { code: "bm", name: "Bambara", keywords: ["bamanankan"] },
  { code: "ba", name: "Bashkir", keywords: ["башҡорт теле"] },
  { code: "eu", name: "Basque", keywords: ["euskara", "euskera"] },
  { code: "be", name: "Belarusian", keywords: ["Беларуская"] },
  { code: "bn", name: "Bengali", keywords: ["বাংলা"] },
  { code: "bi", name: "Bislama", keywords: [] },
  { code: "bs", name: "Bosnian", keywords: ["bosanski jezik"] },
  { code: "br", name: "Breton", keywords: ["brezhoneg"] },
  { code: "bg", name: "Bulgarian", keywords: ["български език"] },
  { code: "my", name: "Burmese", keywords: ["ဗမာစာ"] },
  { code: "ca", name: "Catalan", keywords: ["Valencià", "català", "valencià"] },
  { code: "ch", name: "Chamorro", keywords: ["Chamoru"] },
  { code: "ce", name: "Chechen", keywords: ["нохчийн мотт"] },
  {
    code: "ny",
    name: "Chichewa",
    keywords: ["Chewa", "Nyanja", "chiCheŵa", "chinyanja"],
  },
  { code: "zh", name: "Chinese", keywords: ["中文", "汉语", "漢語"] },
  {
    code: "cu",
    name: "Church Slavonic",
    keywords: ["Old Slavonic", "Old Church Slavonic", "ѩзыкъ словѣньскъ"],
  },
  { code: "cv", name: "Chuvash", keywords: ["чӑваш чӗлхи"] },
  { code: "kw", name: "Cornish", keywords: ["Kernewek"] },
  { code: "co", name: "Corsican", keywords: ["corsu", "lingua corsa"] },
  { code: "cr", name: "Cree", keywords: ["ᓀᐦᐃᔭᐍᐏᐣ"] },
  { code: "hr", name: "Croatian", keywords: ["hrvatski"] },
  { code: "cs", name: "Czech", keywords: ["česky", "čeština"] },
  { code: "da", name: "Danish", keywords: ["dansk"] },
  { code: "dv", name: "Divehi", keywords: ["Dhivehi", "Maldivian", "ދިވެހި"] },
  { code: "nl", name: "Dutch", keywords: ["Flemish", "Nederlands", "Vlaams"] },
  { code: "dz", name: "Dzongkha", keywords: ["རྫོང་ཁ"] },
  { code: "en", name: "English", keywords: [] },
  { code: "eo", name: "Esperanto", keywords: [] },
  { code: "et", name: "Estonian", keywords: ["eesti", "eesti keel"] },
  { code: "ee", name: "Ewe", keywords: ["Eʋegbe"] },
  { code: "fo", name: "Faroese", keywords: ["føroyskt"] },
  { code: "fj", name: "Fijian", keywords: ["vosa Vakaviti"] },
  { code: "fi", name: "Finnish", keywords: ["suomi", "suomen kieli"] },
  { code: "fr", name: "French", keywords: ["français", "langue française"] },
  { code: "fy", name: "Western Frisian", keywords: ["Frysk"] },
  { code: "ff", name: "Fulah", keywords: ["Fulfulde", "Pulaar", "Pular"] },
  { code: "gd", name: "Gaelic", keywords: ["Scottish Gaelic", "Gàidhlig"] },
  { code: "gl", name: "Galician", keywords: ["Galego"] },
  { code: "lg", name: "Ganda", keywords: ["Luganda"] },
  { code: "ka", name: "Georgian", keywords: ["ქართული"] },
  { code: "de", name: "German", keywords: ["Deutsch"] },
  { code: "el", name: "Greek", keywords: ["Modern (1453–)", "Ελληνικά"] },
  { code: "kl", name: "Kalaallisut", keywords: ["Greenlandic"] },
  { code: "gn", name: "Guarani", keywords: ["Avañeʼẽ"] },
  { code: "gu", name: "Gujarati", keywords: ["ગુજરાતી"] },
  {
    code: "ht",
    name: "Haitian",
    keywords: ["Haitian Creole", "Kreyòl ayisyen"],
  },
  { code: "ha", name: "Hausa", keywords: ["Hausa", "هَوُسَ"] },
  { code: "he", name: "Hebrew", keywords: ["עברית"] },
  { code: "hz", name: "Herero", keywords: ["Otjiherero"] },
  { code: "hi", name: "Hindi", keywords: ["हिन्दी", "हिंदी"] },
  { code: "ho", name: "Hiri Motu", keywords: [] },
  { code: "hu", name: "Hungarian", keywords: ["Magyar"] },
  { code: "is", name: "Icelandic", keywords: ["Íslenska"] },
  { code: "io", name: "Ido", keywords: [] },
  { code: "ig", name: "Igbo", keywords: ["Asụsụ Igbo"] },
  { code: "id", name: "Indonesian", keywords: ["Bahasa Indonesia"] },
  { code: "ia", name: "Interlingua", keywords: [] },
  { code: "ie", name: "Interlingue", keywords: ["Occidental"] },
  { code: "iu", name: "Inuktitut", keywords: ["ᐃᓄᒃᑎᑐᑦ"] },
  { code: "ik", name: "Inupiaq", keywords: ["Iñupiaq", "Iñupiatun"] },
  { code: "ga", name: "Irish", keywords: ["Gaeilge"] },
  { code: "it", name: "Italian", keywords: ["Italiano"] },
  { code: "ja", name: "Japanese", keywords: ["日本語", "にほんご"] },
  { code: "jv", name: "Javanese", keywords: ["basa Jawa"] },
  { code: "kn", name: "Kannada", keywords: ["ಕನ್ನಡ"] },
  { code: "kr", name: "Kanuri", keywords: [] },
  { code: "ks", name: "Kashmiri", keywords: ["कश्मीरी", "كشميري‎"] },
  { code: "kk", name: "Kazakh", keywords: ["Қазақ тілі"] },
  { code: "km", name: "Central Khmer", keywords: ["ភាសាខ្មែរ"] },
  { code: "ki", name: "Kikuyu", keywords: ["Gikuyu"] },
  { code: "rw", name: "Kinyarwanda", keywords: [] },
  { code: "ky", name: "Kirghiz", keywords: ["Kyrgyz", "Кыргыз тили"] },
  { code: "kv", name: "Komi", keywords: ["коми кыв"] },
  { code: "kg", name: "Kongo", keywords: ["KiKongo"] },
  { code: "ko", name: "Korean", keywords: ["한국어", "조선말"] },
  { code: "kj", name: "Kuanyama", keywords: ["Kwanyama"] },
  { code: "ku", name: "Kurdish", keywords: ["Kurdî", "كوردی‎"] },
  { code: "lo", name: "Lao", keywords: ["ພາສາລາວ"] },
  { code: "la", name: "Latin", keywords: ["latine", "lingua latina"] },
  { code: "lv", name: "Latvian", keywords: ["latviešu valoda"] },
  {
    code: "li",
    name: "Limburgan",
    keywords: ["Limburger", "Limburgish", "Limburgs"],
  },
  { code: "ln", name: "Lingala", keywords: [] },
  { code: "lt", name: "Lithuanian", keywords: ["lietuvių kalba"] },
  { code: "lu", name: "Luba-Katanga", keywords: [] },
  { code: "lb", name: "Luxembourgish", keywords: ["Letzeburgesch"] },
  { code: "mk", name: "Macedonian", keywords: ["македонски јазик"] },
  { code: "mg", name: "Malagasy", keywords: [] },
  { code: "ms", name: "Malay", keywords: ["bahasa Melayu", "بهاس ملايو‎"] },
  { code: "ml", name: "Malayalam", keywords: ["മലയാളം"] },
  { code: "mt", name: "Maltese", keywords: ["Malti"] },
  { code: "gv", name: "Manx", keywords: ["Gaelg", "Gailck"] },
  { code: "mi", name: "Maori", keywords: ["te reo Māori"] },
  { code: "mr", name: "Marathi", keywords: ["मराठी"] },
  { code: "mh", name: "Marshallese", keywords: ["Kajin M̧ajeļ"] },
  { code: "mn", name: "Mongolian", keywords: ["Монгол"] },
  { code: "na", name: "Nauru", keywords: ["Ekakairũ Naoero"] },
  {
    code: "nv",
    name: "Navajo",
    keywords: ["Navaho", "Diné bizaad", "Dinékʼehǰí"],
  },
  { code: "nd", name: "North Ndebele", keywords: ["isiNdebele"] },
  { code: "nr", name: "South Ndebele", keywords: ["isiNdebele"] },
  { code: "ng", name: "Ndonga", keywords: [] },
  { code: "ne", name: "Nepali", keywords: ["नेपाली"] },
  { code: "no", name: "Norwegian", keywords: ["Norsk"] },
  { code: "nb", name: "Norwegian Bokmål", keywords: ["Norsk bokmål"] },
  { code: "nn", name: "Norwegian Nynorsk", keywords: ["Norsk nynorsk"] },
  { code: "oc", name: "Occitan", keywords: [] },
  { code: "oj", name: "Ojibwa", keywords: ["ᐊᓂᔑᓈᐯᒧᐎᓐ"] },
  { code: "or", name: "Oriya", keywords: ["ଓଡ଼ିଆ"] },
  { code: "om", name: "Oromo", keywords: ["Afaan Oromoo"] },
  { code: "os", name: "Ossetian", keywords: ["Ossetic", "ирон æвзаг"] },
  { code: "pi", name: "Pali", keywords: ["पाऴि"] },
  { code: "ps", name: "Pashto", keywords: ["Pushto", "پښتو"] },
  { code: "fa", name: "Persian", keywords: ["فارسی"] },
  { code: "pl", name: "Polish", keywords: ["polski", "język polski"] },
  { code: "pt", name: "Portuguese", keywords: ["Português"] },
  { code: "pa", name: "Punjabi", keywords: ["Panjabi", "ਪੰਜਾਬੀ", "پنجابی‎"] },
  { code: "qu", name: "Quechua", keywords: ["Runa Simi", "Kichwa"] },
  {
    code: "ro",
    name: "Romanian",
    keywords: ["Moldavian", "Moldovan", "română"],
  },
  { code: "rm", name: "Romansh", keywords: ["rumantsch grischun"] },
  { code: "rn", name: "Rundi", keywords: ["kiRundi"] },
  { code: "ru", name: "Russian", keywords: ["русский язык"] },
  { code: "se", name: "Northern Sami", keywords: ["Davvisámegiella"] },
  { code: "sm", name: "Samoan", keywords: ["gagana faa Samoa"] },
  { code: "sg", name: "Sango", keywords: ["yângâ tî sängö"] },
  { code: "sa", name: "Sanskrit", keywords: ["संस्कृतम्"] },
  { code: "sc", name: "Sardinian", keywords: ["sardu"] },
  { code: "sr", name: "Serbian", keywords: ["српски језик"] },
  { code: "sn", name: "Shona", keywords: ["chiShona"] },
  { code: "sd", name: "Sindhi", keywords: ["सिन्धी", "سنڌي، سندھی‎"] },
  { code: "si", name: "Sinhala", keywords: ["Sinhalese", "සිංහල"] },
  { code: "sk", name: "Slovak", keywords: ["slovenčina"] },
  { code: "sl", name: "Slovenian", keywords: ["slovenščina"] },
  { code: "so", name: "Somali", keywords: ["Soomaaliga", "af Soomaali"] },
  { code: "st", name: "Southern Sotho", keywords: [] },
  {
    code: "es",
    name: "Spanish",
    keywords: ["Castilian", "español", "castellano"],
  },
  { code: "su", name: "Sundanese", keywords: ["Basa Sunda"] },
  { code: "sw", name: "Swahili", keywords: ["Kiswahili"] },
  { code: "ss", name: "Swati", keywords: ["siSwati"] },
  { code: "sv", name: "Swedish", keywords: ["svenska"] },
  { code: "tl", name: "Tagalog", keywords: ["Wikang Tagalog"] },
  { code: "ty", name: "Tahitian", keywords: ["Reo Tahiti"] },
  { code: "tg", name: "Tajik", keywords: ["тоҷикӣ", "toğikī", "تاجیکی‎"] },
  { code: "ta", name: "Tamil", keywords: ["தமிழ்"] },
  { code: "tt", name: "Tatar", keywords: ["татар теле", "tatar tele"] },
  { code: "te", name: "Telugu", keywords: ["తెలుగు"] },
  { code: "th", name: "Thai", keywords: ["ไทย"] },
  { code: "bo", name: "Tibetan", keywords: ["བོད་ཡིག"] },
  { code: "ti", name: "Tigrinya", keywords: ["ትግርኛ"] },
  { code: "to", name: "Tonga", keywords: ["Tonga Islands", "lea faka-Tonga"] },
  { code: "ts", name: "Tsonga", keywords: ["Xitsonga"] },
  { code: "tn", name: "Tswana", keywords: ["Setswana"] },
  { code: "tr", name: "Turkish", keywords: ["Türkçe"] },
  { code: "tk", name: "Turkmen", keywords: ["Türkmen", "Түркмен"] },
  { code: "tw", name: "Twi", keywords: ["Twi"] },
  {
    code: "ug",
    name: "Uighur",
    keywords: ["Uyghur", "ئۇيغۇرچە‎", "Uyghurche"],
  },
  { code: "uk", name: "Ukrainian", keywords: ["українська мова"] },
  { code: "ur", name: "Urdu", keywords: ["اردو"] },
  { code: "uz", name: "Uzbek", keywords: ["O'zbek", "Ўзбек", "أۇزبېك‎"] },
  { code: "ve", name: "Venda", keywords: ["Tshivenḓa"] },
  { code: "vi", name: "Vietnamese", keywords: ["Tiếng Việt"] },
  { code: "vo", name: "Volapük", keywords: ["Volapük"] },
  { code: "wa", name: "Walloon", keywords: ["walon"] },
  { code: "cy", name: "Welsh", keywords: ["Cymraeg"] },
  { code: "wo", name: "Wolof", keywords: ["Wollof"] },
  { code: "xh", name: "Xhosa", keywords: ["isiXhosa"] },
  { code: "ii", name: "Sichuan Yi", keywords: ["Nuosu", "ꆈꌠ꒿"] },
  { code: "yi", name: "Yiddish", keywords: ["ייִדיש"] },
  { code: "yo", name: "Yoruba", keywords: ["Yorùbá"] },
  {
    code: "za",
    name: "Zhuang",
    keywords: ["Chuang", "Saɯ cueŋƅ", "Saw cuengh"],
  },
  { code: "zu", name: "Zulu", keywords: ["isiZulu"] },
];

const LanguageDropdown = ({ onSelect }) => {
  const handleSelect = (selectedLanguage) => {
    const selectedLanguageObject = languages.find(
      (lang) =>
        lang.name === selectedLanguage ||
        lang.keywords.includes(selectedLanguage),
    );
    onSelect(selectedLanguageObject);
  };

  return (
    <AutoCompleteDropdown
      options={languages.map((lang) => [lang.name, ...lang.keywords])}
      onSelect={handleSelect}
    />
  );
};

export default LanguageDropdown;
