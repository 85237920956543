import React, { useEffect } from "react";
import TextArea from "./TextArea";

export default function AutoGrowTextArea({
  value,
  maxHeight,
  rows = 1,
  focus = false,
  ...props
}) {
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      // Temporarily reset height to 2rem per row of text plus 2rem margin
      // before calculating the new height:
      ref.current.style.height = "" + (rows * 2 + 2) + "rem";

      // Add two pixels for the 1px border on either side
      const newHeight = ref.current.scrollHeight + 2;

      // Set the new height, respecting the maxHeight if provided
      ref.current.style.height = `${
        maxHeight ? Math.min(maxHeight, newHeight) : newHeight
      }px`;
    }
  }, [value, rows, maxHeight]);

  useEffect(() => {
    if (focus && ref.current) {
      ref.current.focus();
    }
  }, [focus]);

  return (
    <TextArea
      ref={ref}
      value={value}
      style={{ overflow: "hidden", minHeight: `${4}rem`, height: `${4}rem` }}
      {...props}
    />
  );
}
