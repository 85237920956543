import styled, { css } from "styled-components";
import { colors } from "../constants.js";

export default styled.button`
  border: 1px solid rgba(151, 151, 151, 0.8);
  outline: none;
  background: none;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.125rem;
  padding: 1.25rem 2rem;
  transition: 0.2s border;
  min-width: 13rem;
  letter-spacing: 1px;
  box-sizing: border-box;
  pointer-events: all;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${colors.primary};
  }

  ${({ primary }) =>
    primary &&
    css`
      background: ${colors.primary};
      border: none;
    `}

  ${({ gridMargin }) =>
    gridMargin &&
    css`
      margin-right: ${gridMargin};
      margin-bottom: ${gridMargin};
    `}

  &:disabled {
    color: ${colors.grey};
    curson: not-allowed;
    pointer-events: none;
  }
`;
