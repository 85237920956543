import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logEvent, postRequest } from "../common.js";
import Page from "./layout/Page.js";
import BackButton from "./atoms/BackButton.js";
import { SettingsContext } from "./ContextProvider.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";
import LanguageDropdown from "./atoms/LanguageDropdown.js";
import { toast } from "react-toastify";

const defaultSettings = {
  languages: ["English"],
  allowedContent: [],
  globalRankThreshold: 2000,
  episodeRankPercentileThreshold: 10,
  autoAdvanceEmptyScenes: true,
  autoAdvanceScenesWithSubtitles: false,
  showEnglishSentenceTranslation: true,
  showEnglishWordTranslations: true,
  showWordPronunciations: true,
  useKanaForPronunciations: false,
  showJapaneseWords: true,
  showKanjiTranslations: true,
};

// Note: use this getter to access settings values in other components. That way the defaults
// will respect the defaults in the defaultSettings object.
export function getSetting(settings, key) {
  return settings?.[key] ?? defaultSettings[key];
}

const SettingsContainer = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const LanguageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LanguageItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const SubSectionTitle = styled.h3`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledCheckbox = styled.input`
  margin-right: 1rem;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
`;

const InputLabel = styled.label`
  margin-right: 1rem;
  text-align: center;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 0.25rem;
`;

export default function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useContext(SettingsContext);

  const languages = getSetting(settings, "languages");
  const allowedContent = getSetting(settings, "allowedContent");

  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => navigate("/")} />
    </Row>
  );
  const headerCenterContent = <h1>Settings</h1>;

  const handleUpdateSettings = async (field, value, description) => {
    const updatedSettings = { ...settings, [field]: value };
    setSettings(updatedSettings);
    try {
      await postRequest("/api/settings/update", updatedSettings, null, true);

      setSettings(updatedSettings);
      toast.success(`${description} updated successfully`);
      return true;
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(`Failed to update ${description}. Please try again.`);
      return false;
    }
  };

  const handleAddLanguage = async (selectedLanguage) => {
    selectedLanguage = selectedLanguage.name;
    if (selectedLanguage && !languages.includes(selectedLanguage)) {
      const newLanguages = [...languages, selectedLanguage];
      const result = await handleUpdateSettings(
        "languages",
        newLanguages,
        `Support for ${selectedLanguage}`,
      );
      if (result) {
        logEvent("add-language", { language: selectedLanguage });
      }
    }
  };

  const validatePositiveInteger = (event) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue <= 0) {
      event.target.style.color = "red";
      toast.error("Setting must be a positive integer");
      return null;
    }
    event.target.style.color = "black";
    return parsedValue;
  };

  const handleUpdateGlobalRankThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }
    const result = await handleUpdateSettings(
      "globalRankThreshold",
      value,
      "Global Rank Threshold",
    );
    if (result) {
      logEvent("update-global-rank-threshold", { value });
    }
  };

  const handleUpdateEpisodeRankPercentileThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }
    const result = await handleUpdateSettings(
      "episodeRankPercentileThreshold",
      value,
      "Episode Rank Percentile Threshold",
    );
    if (result) {
      logEvent("update-episode-rank-percentile-threshold", { value });
    }
  };

  const handleDeleteLanguage = async (language) => {
    const newLanguages = languages.filter((lang) => lang !== language);
    const result = await handleUpdateSettings(
      "languages",
      newLanguages,
      `Support for ${language}`,
    );
    if (result) {
      logEvent("delete-language", { language });
    }
  };

  const handleContentChange = async (service) => {
    const newAllowedContent = allowedContent.includes(service)
      ? allowedContent.filter((item) => item !== service)
      : [...allowedContent, service];
    const result = await handleUpdateSettings(
      "allowedContent",
      newAllowedContent,
      `Content ownership for ${service}`,
    );
    if (result) {
      logEvent("update-content-ownership", {
        service,
        checked: newAllowedContent.includes(service),
      });
    }
  };

  useEffect(() => {
    document.title = "Settings - LingoFlix";
  }, []);

  const makeSettingsCheckbox = (field, description) => (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id={field}
        checked={getSetting(settings, field)}
        onChange={() =>
          handleUpdateSettings(field, !getSetting(settings, field), description)
        }
      />
      <CheckboxLabel htmlFor={field}>{description}</CheckboxLabel>
    </CheckboxContainer>
  );

  const makeContentConfirmationCheckbox = (service, description) => (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id={service}
        checked={allowedContent.includes(service)}
        onChange={() => handleContentChange(service)}
      />
      <CheckboxLabel htmlFor={service}>{description}</CheckboxLabel>
    </CheckboxContainer>
  );

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <SettingsContainer>
        <SectionTitle>Languages</SectionTitle>
        <LanguageList>
          {languages.map((language) => (
            <LanguageItem key={language}>
              {language}
              {language !== "English" && (
                <DeleteButton onClick={() => handleDeleteLanguage(language)}>
                  Delete
                </DeleteButton>
              )}
            </LanguageItem>
          ))}
        </LanguageList>
        <Row $gap="1rem">
          <LanguageDropdown onSelect={handleAddLanguage} />
        </Row>

        <SectionTitle>Frequent Word Highlighting</SectionTitle>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={getSetting(settings, "globalRankThreshold")}
            onChange={handleUpdateGlobalRankThreshold}
          />
          <InputLabel>Global Rank Threshold</InputLabel>
        </Row>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={getSetting(settings, "episodeRankPercentileThreshold")}
            onChange={handleUpdateEpisodeRankPercentileThreshold}
          />
          <InputLabel>Episode Rank Percentile Threshold</InputLabel>
        </Row>

        <SectionTitle>Playback</SectionTitle>
        {makeSettingsCheckbox(
          "autoAdvanceEmptyScenes",
          "Auto-advance empty scenes",
        )}
        {makeSettingsCheckbox(
          "autoAdvanceScenesWithSubtitles",
          "Auto-advance scenes with subtitles",
        )}

        <SectionTitle>Subtitle Display</SectionTitle>
        {makeSettingsCheckbox(
          "showEnglishSentenceTranslation",
          "Show English sentence translation",
        )}
        <SubSectionTitle>Word Display</SubSectionTitle>
        {makeSettingsCheckbox(
          "showEnglishWordTranslations",
          "Show English word translations",
        )}
        {makeSettingsCheckbox(
          "showWordPronunciations",
          "Show Word Pronunciations",
        )}
        {makeSettingsCheckbox(
          "useKanaForPronunciations",
          "Use Kana for pronunciations",
        )}
        {makeSettingsCheckbox("showJapaneseWords", "Show Japanese Words")}
        {makeSettingsCheckbox(
          "showKanjiTranslations",
          "Show Kanji Translations",
        )}

        <SectionTitle>Content Access Confirmations</SectionTitle>
        {makeContentConfirmationCheckbox(
          "netflix",
          "I'm a paid subscriber to Netflix",
        )}
        {makeContentConfirmationCheckbox(
          "youtube",
          "I'm a paid subscriber to YouTube",
        )}
      </SettingsContainer>
    </Page>
  );
}
