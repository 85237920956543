import styled from "styled-components";

export default styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #373435;
  height: 2px;

  & span {
    display: block;
    background-color: white;
    height: 2px;
  }
`;
