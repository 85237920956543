import React, { useState, useEffect, useCallback, useMemo } from "react";
import Popup from "./Popup.js";
import {
  formatDate,
  getRequest,
  postRequest,
  deleteRequest,
} from "../../common.js";
import { ChatComponent } from "./ChatComponent.js";
import Dropdown from "./Dropdown.js";
import { toast } from "react-toastify";
import BreakdownButton from "../atoms/BreakdownButton.js";
import Tooltip from "../atoms/Tooltip.js";
import IconLock from "../../assets/icons/lock.svg";
import IconLockOpen from "../../assets/icons/lock-open.svg";
import IconComment from "../../assets/icons/comment.svg";
import IconTrashCan from "../../assets/icons/trash-can.svg";

export default function ChatPopup({
  episode,
  videoPath,
  parentBreakdown,
  childBreakdown,
  defaultThread,
  scrollStart,
  setPopupState,
}) {
  const closePopups = () => setPopupState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [messages, setMessages] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [activeThread, setActiveThread] = useState(null);
  const [threads, setThreads] = useState([]);
  // This variable controls whether newly created threads are shared or not.
  const [newShared, setNewShared] = useState(true);

  // This variable turns off scrollStart when false.
  const [fresh, setFresh] = useState(true);

  const [threadName, setThreadName] = useState("");

  const addAnkiButtons = useCallback((messageText) => {
    // Extract blockquoted text:
    const lines = messageText.split("\n");
    let blockquotedLines = [];
    let inBlockquote = false;
    for (let line of lines) {
      if (line.startsWith("```")) {
        inBlockquote = !inBlockquote;
      } else if (inBlockquote) {
        blockquotedLines.push(line);
      }
    }

    // Build cards:
    let ankiCards = [];
    for (let line of blockquotedLines) {
      const fields = line.split(",");
      if (fields.length !== 3) {
        continue;
      }
      if (fields[0] === "source") {
        continue;
      }

      // TODO: When refactoring breakdown node references (issue #55), we should add a child reference
      ankiCards.push({
        jmdMeaning: "",
        original: `${fields[0]} -> ${fields[1]}`, // card back.
        romanji: "",
        translation: `${fields[0]} + ${fields[2]} = ?`, // card front.
        sentenceTranslation: "", // TODO: add this back?
        videoPath,
        sentenceSpans: [],
        episodeId: episode.id,
      });
    }
    if (ankiCards.length === 0) {
      return null;
    }
    return {
      ankiCards,
      isUser: false,
      timestamp: formatDate(new Date()),
    };
  }, []);

  const suggestions = useMemo(() => {
    const breakdown = childBreakdown;
    let allSuggestions = [];
    const isSentence = !childBreakdown;
    const textType = isSentence ? "sentence" : "word";
    allSuggestions.push(`Explain the ${textType}`);
    if (breakdown && !isSentence) {
      if (
        breakdown.pronunciation &&
        breakdown.translations &&
        breakdown.translations.length > 0
      ) {
        allSuggestions.push(
          `Construct a mnemonic to help me remember that the Japanese word "${breakdown.pronunciation}" means "${breakdown.translations[0]}".`,
        );
      }
      allSuggestions.push(
        `Show other Japanese words that are etymologically related to ${breakdown.original}`,
      );
      const kanji = (breakdown.characters || []).filter(
        (c) => c.alphabet === "kanji",
      );
      if (kanji.length > 0) {
        const kanjiForDisplay = kanji.map(
          (k, i) => (i !== 0 ? " and " : "") + k.original,
        );
        allSuggestions.push(
          `Provide a mnemonic associating the meanings of the kanji ${kanjiForDisplay} with the word "${breakdown.original}"`,
        );
      }
    }
    allSuggestions.push(`Explain how to pronounce the ${textType}`);
    allSuggestions.push(`Explain how this ${textType} is used`);
    if (isSentence) {
      allSuggestions.push(
        "Explain how the particles/copula structure the sentence",
      );
      allSuggestions.push("Explain the sentence grammatically");
    } else {
      allSuggestions.push("Name an example sentence using the word");
      allSuggestions.push(
        "Explain the meaning of each kanji in " + breakdown.original,
      );
    }

    allSuggestions = allSuggestions.map((x) => ({
      name: x,
      prompt: x,
    }));

    if (breakdown && !isSentence) {
      // TODO filter POS for verb?

      let prompt = `Hi!
      In our UI, a grid displays the components of a Japanese word or phrase, where each component is a substring of the term to be broken down. Clicking on a component reveals its basic meaning and an Anki card, which you can add to your deck. Note: the last two columns are for UI integration only and not included in the Anki card.
      I would like a CSV of nice Anki cards to learn Japanese building blocks and lego-logic. I am creating "decompositions" of Japanese phrases or words, where each Anki card explores a component or aspect of the larger structure to help learn those. For example, if the word is 取れなくって, I would like these Anki cards:

\`\`\`
source,target,transformation
取る,取れる,Godan verb to potential form
取れる,取れない,Ichidan verb to negative adjective
取れない,取れなく,i-adjective to adverb
取れなく,取れなくって,adverb to colloquial connective
\`\`\`
Please do this for the word "${breakdown.original}". First take a deep breath and explain your thought process and then construct the CSV in a single code-block.
`;
      allSuggestions.push({
        name: "Break down the word",
        prompt: prompt,
        callback: addAnkiButtons,
      });
    }
    return allSuggestions;
  }, [childBreakdown, addAnkiButtons]);

  const loadMessages = useCallback(
    (messages) => {
      messages = messages.map((m) => ({
        ...m,
        timestamp: formatDate(new Date(m.timestamp)),
      }));
      let newMessages = [];
      for (let i = 0; i < messages.length; i++) {
        newMessages.push(messages[i]);

        // If we constructed any ANKI cards in the past we reconstruct them here.
        if (messages[i].isUser) {
          for (let suggestion of suggestions) {
            if (suggestion.prompt === messages[i].message) {
              newMessages.push(messages[i + 1]);
              const callbackRes = suggestion.callback
                ? suggestion.callback(messages[i + 1]?.message)
                : null;
              if (callbackRes) newMessages.push(callbackRes);
              i++; // Skip over the reply.
              break;
            }
          }
        }
      }
      setMessages(newMessages);
      setShowSuggestions(messages.length === 0);
    },
    [suggestions],
  );

  const loadInitialThread = (loadLastThread) => {
    let url = `/api/chat/info?episodeId=${episode.id}&breakdownIndex=${parentBreakdown.index}`;
    if (childBreakdown && typeof childBreakdown.index === "number") {
      url += `&wordIndex=${childBreakdown.index}`;
    }
    getRequest(url, {}, (info) => {
      setThreads(info.threads);
      if (info.activeThread && loadLastThread) {
        setActiveThread(info.activeThread);
        setThreadName(info.activeThread.name);
        loadMessages(info.messages);
      } else {
        setShowSuggestions(true);
      }
      setInitialLoading(false);
    });
  };

  const refreshMessages = useCallback(
    (loadLastThread = true) => {
      if (defaultThread) {
        openThread(defaultThread);
      } else {
        loadInitialThread(loadLastThread);
      }
    },
    [episode, parentBreakdown, childBreakdown, loadMessages],
  );

  const deleteChat = useCallback(() => {
    const threadName = activeThread.name;
    let url = "/api/chat";
    const body = { thread: activeThread.id };
    if (childBreakdown && typeof childBreakdown.index === "number") {
      body.wordIndex = childBreakdown.index;
    }
    deleteRequest(
      url,
      body,
      () => {
        setMessages([]);
        setActiveThread(null);
        setThreadName("");
        refreshMessages(false);
        toast(
          <span>
            <img src={IconTrashCan} width="16" height="16" /> Chat named{" "}
            <i>{threadName}</i> deleted
          </span>,
        );
      },
      true,
    );
  }, [activeThread, childBreakdown]);

  useEffect(refreshMessages, [refreshMessages]);

  const openThread = useCallback((thread) => {
    setActiveThread(thread);
    setThreadName(thread.name);
    getRequest("/api/chat", { thread: thread.id }, (messages) => {
      loadMessages(messages);
      setInitialLoading(false);
    });
  }, []);

  const shareChat = useCallback(() => {
    postRequest(
      "/api/chat/thread_share",
      {
        thread: activeThread.id,
        shared: !activeThread.shared,
      },
      () => {
        setActiveThread({ ...activeThread, shared: !activeThread.shared });
        toast(
          <span>
            <img
              src={activeThread.shared ? IconLock : IconLockOpen}
              width="16"
              height="16"
            />
            &nbsp; Chat named <i>{activeThread.name}</i>{" "}
            {activeThread.shared ? "unshared" : "shared"}
          </span>,
        );
      },
    );
  }, [activeThread, refreshMessages]);

  const title = activeThread ? (
    <>
      {!activeThread.isMine && activeThread.username && (
        <strong>
          <i>{`[${activeThread.username}] `}</i>
        </strong>
      )}
      {threadName}
    </>
  ) : (
    "New Chat"
  );

  return (
    !initialLoading && (
      <Popup
        title={
          <>
            {threads.length > 0 &&
            !(threads.length === 1 && threads[0].id === activeThread?.id) ? (
              <Dropdown
                style={{ width: "100%" }}
                title={title}
                stretchContextMenu={true}
                options={threads.map((t) => ({
                  label: (
                    <>
                      {t.isMine ? (
                        ""
                      ) : (
                        <strong>
                          <i>{`[${t.username}] `}</i>
                        </strong>
                      )}
                      {t.name}
                    </>
                  ),
                  action: () => {
                    openThread(t);
                  },
                }))}
              />
            ) : (
              title
            )}
          </>
        }
        onClose={closePopups}
        contextMenuOptions={
          activeThread
            ? [
                !defaultThread && {
                  label: (
                    <span>
                      <img src={IconComment} width="16" height="16" /> New Chat
                    </span>
                  ),
                  action: () => {
                    setNewShared(true);
                    setMessages([]);
                    setShowSuggestions(true);
                    setActiveThread(null);
                  },
                },
                activeThread.isMine && {
                  label: (
                    <span>
                      <img src={IconTrashCan} width="16" height="16" /> Delete
                      Chat
                    </span>
                  ),
                  action: deleteChat,
                },
                activeThread.isMine && {
                  label: activeThread.shared ? (
                    <span>
                      <img src={IconLock} width="16" height="16" /> Unshare Chat
                    </span>
                  ) : (
                    <span>
                      <img src={IconLockOpen} width="16" height="16" /> Share
                      Chat
                    </span>
                  ),
                  action: shareChat,
                },
              ]
            : []
        }
        topLeft={
          !activeThread && (
            <Tooltip text="A closed lock means the chat is private, an open lock means it is shared.">
              <BreakdownButton
                className="breakdown-button"
                onClick={() => setNewShared((shared) => !shared)}
              >
                <img
                  src={newShared ? IconLockOpen : IconLock}
                  width="20"
                  height="20"
                />
              </BreakdownButton>
            </Tooltip>
          )
        }
      >
        <ChatComponent
          episode={episode}
          parentBreakdown={parentBreakdown}
          childBreakdown={childBreakdown}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          suggestions={suggestions}
          messages={messages}
          setMessages={setMessages}
          activeThread={activeThread}
          setActiveThread={setActiveThread}
          newShared={newShared}
          setThreads={setThreads}
          scrollStart={scrollStart && fresh}
          setFresh={setFresh}
          setThreadName={setThreadName}
        />
      </Popup>
    )
  );
}
