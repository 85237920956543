import React, { useMemo, useCallback, useContext } from "react";
import { SettingsContext } from "../ContextProvider.js";
import { getSetting } from "../Settings.js";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Phrase from "./phrase/Phrase.js";
import Row from "../layout/Row.js";
import { isMobileOnly } from "react-device-detect";
import Dropdown from "./Dropdown.js";
import { containsParathesis } from "../../common.js";
import IconComment from "../../assets/icons/comment.svg";
import IconPenToSquare from "../../assets/icons/pen-to-square.svg";
import IconCircleXmark from "../../assets/icons/circle-xmark.svg";

const EnglishSubtitleLine = styled.span`
  fontWeight: "normal",
  fontSize: "1rem",
  whiteSpace: "pre-line",
  marginBottom: "1rem",
  flexGrow: 1,
`;

const UpperRightCornerContainer = styled.div`
  position: absolute;
  right: 0rem;
  top: -0.5rem; /* Note: we move the button upward to prevent it from running into the second row on iOS. */
`;

const PhraseContainer = styled.div`
  display: inline-block;
  margin-left: ${(props) =>
    props.$padLeft ? (isMobileOnly ? "1rem" : "2rem") : "0"};
  margin-right: ${(props) =>
    props.$padRight ? (isMobileOnly ? "1rem" : "2rem") : "0"};
  margin-bottom: ${isMobileOnly ? "1rem" : "2rem"};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$showMetaColumn ? "auto 1fr" : "1fr"};
  column-gap: 3px;
`;

const MetaInfoColumn = styled.div`
  border-right: 2px solid gray;
`;

export default function DetailedSubs({
  episode,
  breakdown,
  breakdownMenuOptions,
  popoverState,
  setPopoverState,
  setPopupState,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  registerPopoverButton,
}) {
  const [settings] = useContext(SettingsContext);
  const showEnglishSentenceTranslation = getSetting(
    settings,
    "showEnglishSentenceTranslation",
  );

  const navigate = useNavigate();
  const returnToShowMenu = () => navigate(`/browse/${episode.show.url}`);

  const sentences = useMemo(
    () =>
      (breakdown.words && breakdown.words.length ? breakdown.words : [])
        .filter(
          (e, i, arr) =>
            arr.find((el) => el.sentences[0] === e.sentences[0]).index === i,
        )
        .map((e) =>
          breakdown.words.filter((el) => el.sentences[0] === e.sentences[0]),
        ),
    [breakdown],
  );
  const metaInfoCounts = useMemo(() => {
    const counts = {};
    sentences.forEach((sentence, i) => {
      let pos = sentence.findIndex(
        (word) =>
          !word?.is_parenthetical && !containsParathesis(word?.original),
      );
      counts[i] = pos === -1 ? sentence.length : pos;
    });
    return counts;
  }, [sentences]);
  const someSentenceHasMetaInfo = Object.values(metaInfoCounts).some(
    (count) => count > 0,
  );

  const englishSubsLines =
    breakdown &&
    breakdown.translations &&
    breakdown.translations.length &&
    showEnglishSentenceTranslation
      ? breakdown.translations[0].split("\n")
      : [];
  const englishSubs = englishSubsLines.map((line) => {
    // If the line starts with a parenthetical, we break it out, otherwise we leave `meta` empty.
    const match = line.match(/^\s*(\(.*\))\s*(.*?)\s*$/);
    return match ? { meta: match[1], line: match[2] } : { meta: "", line };
  });

  const enoughSpace = window.innerWidth > 600;
  const showMetaColumn =
    enoughSpace &&
    (someSentenceHasMetaInfo || englishSubs.some((e) => e.meta.length > 0));

  const hasBreakdown =
    breakdown && breakdown.words && breakdown.words.length > 0;
  const menu = (
    <>
      <Dropdown
        direction="right"
        buttonFontSize={16}
        buttonVerticalPadding={2}
        buttonHorizontalPadding={5}
        buttonBorderRadius={5}
        options={[
          hasBreakdown && {
            label: (
              <span>
                <img src={IconComment} width="16" height="16" /> Chat
              </span>
            ),
            action: () => setPopupState({ popup: "chat" }),
          },
          {
            label: (
              <span>
                <img src={IconPenToSquare} width="16" height="16" /> Feedback
              </span>
            ),
            action: () => setPopupState({ popup: "feedback", breakdown }),
          },
          ...(breakdownMenuOptions || []),
          {
            label: (
              <span>
                <img src={IconCircleXmark} width="16" height="16" /> Exit Video
              </span>
            ),
            action: returnToShowMenu,
          },
        ].filter(Boolean)}
      />
    </>
  );

  const phraseElement = useCallback(
    (breakdown) => {
      return (
        <Phrase
          breakdown={breakdown}
          getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
          setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          popoverState={popoverState}
          setPopoverState={setPopoverState}
          registerPopoverButton={registerPopoverButton}
        />
      );
    },
    [
      getWordBreakdownKnowledgeState,
      setWordBreakdownKnowledgeState,
      popoverState,
      setPopoverState,
      registerPopoverButton,
    ],
  );

  return (
    <div style={{ position: "relative" }}>
      <UpperRightCornerContainer>{menu}</UpperRightCornerContainer>
      <Grid $showMetaColumn={showMetaColumn}>
        {englishSubs.map((eng, i) => {
          const hasMetaInfo = eng.meta.length > 0 && showMetaColumn;
          return (
            <React.Fragment key={`englishSubs${i}`}>
              {showMetaColumn && (
                <MetaInfoColumn>
                  <PhraseContainer>
                    {/*TODO: Setting $isGray didn't work here for some reason */}
                    <span style={{ color: "rgba(140, 140, 140, 0.85)" }}>
                      {hasMetaInfo && eng.meta}
                    </span>
                  </PhraseContainer>
                </MetaInfoColumn>
              )}
              <Row>
                <PhraseContainer
                  $padLeft={showMetaColumn}
                  style={{ paddingRight: i === 0 ? "50px" : null }}
                >
                  {/* Note: we right pad the first translation line to make
                  sure we don't run into our upper right corner button. */}
                  <EnglishSubtitleLine>{eng.line}</EnglishSubtitleLine>
                </PhraseContainer>
              </Row>
            </React.Fragment>
          );
        })}
        {sentences.map((sentence, i) => {
          const metaInfoCount = metaInfoCounts[i];
          return (
            <React.Fragment key={`sentence${i}`}>
              {showMetaColumn && (
                <MetaInfoColumn>
                  {sentence.slice(0, metaInfoCount).map((child, j) => (
                    <PhraseContainer
                      key={`phrase${j}`}
                      $padRight={j === metaInfoCount - 1}
                    >
                      {phraseElement(child)}
                    </PhraseContainer>
                  ))}
                </MetaInfoColumn>
              )}
              <Row
                $doWrap
                style={{
                  paddingRight:
                    englishSubs.length === 0 && i === 0 ? "50px" : null,
                }}
              >
                {sentence.slice(metaInfoCount).map((child, j) => (
                  <PhraseContainer
                    key={`phrase${j}`}
                    $padLeft={showMetaColumn || j > 0}
                  >
                    {phraseElement(child)}
                  </PhraseContainer>
                ))}
              </Row>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
}
