import styled, { css } from "styled-components";
import { colors } from "../constants.js";

export default styled.div`
  width: 20rem;
  background: rgba(32, 32, 32, 0.95);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  ${({ $centerText, $toggled, $fillBox }) => css`
    ${$centerText && "text-align: center;"}
    ${$toggled && `border-color: ${colors.primary};`}
    ${$fillBox &&
    "min-width: calc(100% - 1rem); max-width: 100%; min-height: calc(100% - 2rem); max-height: 100%;"}
  `}
`;
