import styled from "styled-components";

export default styled.li`
  position: relative;
  list-style-type: none;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  flex-direction: column-reverse;
  color: white;
  text-shadow: 0px 0px 5px black;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.39px;
  box-sizing: border-box;
  width: 320px;
  height: 180px;
  background-color: var(--placeholder-color);
  background-size: cover;
  padding: 1rem;
  vertical-align: bottom;
  transition: 0.2s box-shadow;
  ${({ $backgroundImage }) =>
    $backgroundImage && `background-image: url("${$backgroundImage}");`}

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px var(--azure);
  }

  border: ${({ $highlight }) => $highlight && "2px solid white;"};
`;
