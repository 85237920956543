import React from "react";
import styled from "styled-components";
import Column from "./layout/Column.js";
import Row from "./layout/Row.js";
import { highlightCharacterSubstring } from "lingoflix-shared/src/utils.js";

const WordContainer = styled.div`
  text-align: center;

  & p {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    color: inherit;
    text-align: center;
  }

  & h1 {
    font-size: 1.625rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: inherit;
    text-align: center;
  }

  & .muted {
    text-align: center;
    color: rgba(140, 140, 140, 0.85);
  }
`;

export default function Word({
  rubyBreakdown,
  highlight,
  underText,
  showUnderText = true,
}) {
  let highlightedBreakdown = highlightCharacterSubstring(
    rubyBreakdown ?? [],
    highlight,
  );
  return (
    <WordContainer>
      <Column $gap={0}>
        <Row $gap="0.3rem" style={{ justifyContent: "center" }}>
          {highlightedBreakdown.map(({ highlight, original, romaji }, i) => (
            <Column $gap={0} key={i} className={highlight ? "lit" : "muted"}>
              <p style={{ minHeight: "1.5rem" }}>{romaji}</p>
              <h1>{original}</h1>
            </Column>
          ))}
        </Row>
        {showUnderText && (
          <div>{underText ? <p>{underText}</p> : <p>&nbsp;</p>}</div>
        )}
      </Column>
    </WordContainer>
  );
}
