import styled from "styled-components";

export default styled.section`
  & > h1 {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 20px;
  }

  & > h2 {
    margin-bottom: 1rem;
  }
`;
