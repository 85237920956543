import styled, { css } from "styled-components";

export default styled.div`
  display: flex;
  ${({
    baseline,
    $flexStart,
    $flexEnd,
    $center,
    $justifyCenter,
    $justifySpaceBetween,
    transparentBackground,
    $doWrap,
    $gap,
    $rowGap,
  }) => css`
    ${baseline && "align-items: baseline;"}
    ${$flexStart && "align-items: flex-start;"}
    ${$flexEnd && "align-items: flex-end;"}
    ${$center && "align-items: center;"}
    ${$justifyCenter && "justify-content: center;"}
    ${$justifySpaceBetween && "justify-content: space-between;"}
    ${transparentBackground && "background-color: rgba(0, 0, 0, 0.45);"}
    ${$doWrap && "flex-wrap: wrap;"}
    ${$gap && `gap: ${$gap};`}
    ${$rowGap && `column-gap: ${$rowGap};`}
  `}

  ${({ $pb }) =>
    $pb &&
    css`
      padding-bottom: ${$pb};
    `}
  ${({ $scrollable }) =>
    $scrollable &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 1.5rem; /* Make space for the scrollbar */
    `}
`;
