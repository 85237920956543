import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import "swiper/css/bundle";
import { SettingsContext } from "../ContextProvider.js";
import { getSetting } from "../Settings.js";
import styled from "styled-components";
import VideoBackground from "../layout/VideoBackground.js";
import BreakdownOverlay from "../breakdown/BreakdownOverlay.js";
import PopoverManager from "../breakdown/phrase/PopoverManager.js";
import Popup from "../breakdown/Popup.js";
import AnkiCreator from "../breakdown/anki/AnkiCreator.js";
import ChatPopup from "../breakdown/ChatPopup.js";
import AnkiDeckPopup from "../breakdown/anki/AnkiDeckPopup.js";
import FeedbackPopup from "../breakdown/FeedbackPopup.js";
import IconPlay from "../../assets/icons/play.svg";
import { getRequest } from "../../common.js";

const Container = styled.div`
  overflow: hidden;
`;

const FlexWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const FlexGrower = styled.div`
  flex-grow: 1;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url("${IconPlay}") no-repeat center;
  cursor: pointer;
  z-index: 15;
`;

const PosterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.poster})`};
  background-size: cover;
  background-position: center;
  z-index: 5;
`;

const VIDEO_ASPECT_RATIO = { width: 16, height: 9 };

export default function Scene({
  videoElementRef,
  episode,
  fragment,
  subtitle,
  index,
  posterUrl,
  isActiveScene,
  isTransitionalScene,
  isLastSoloScene,
  popoverState,
  setPopoverState,
  popupState,
  setPopupState,
  subtitlesVisible,
  setSubtitlesVisible,
  createCardCallback,
  breakdownMenuOptions,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  indicateReady,
  popoverHasScrollbars,
  setPopoverHasScrollbars,
  advanceSwiper,
}) {
  const [settings] = useContext(SettingsContext);

  const videoContainerRef = useRef(null);
  const wasJustActiveScene = useRef(false);
  const wasJustLastSoloScene = useRef(false);

  const [swiper, setSwiper] = useState();
  const [playFailed, setPlayFailed] = useState(false);
  const [videoHeight, setVideoHeight] = useState(0);
  const [emptySpace, setEmptySpace] = useState(0);
  const [breakdownScroll, setBreakdownScroll] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [popoverButtons, setPopoverButtons] = useState({});

  const videoPath = `${episode.path}/${fragment}.mp4`;
  const videoMiddleFramePath = `/video/${episode.path}/.${fragment}.png`;

  const registerPopoverButton = useCallback(
    (phraseIndex, button) => {
      setPopoverButtons((prev) => ({ ...prev, [phraseIndex]: button }));
    },
    [setPopoverButtons],
  );
  const getPopoverButton = useCallback(
    (phraseIndex) => popoverButtons[phraseIndex],
    [popoverButtons],
  );

  function closePopups() {
    setPopupState(null);
  }

  const calculateVideoDimensions = useCallback(() => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;
    const videoWidth = pageWidth;
    const videoHeight =
      (videoWidth * VIDEO_ASPECT_RATIO.height) / VIDEO_ASPECT_RATIO.width;
    setVideoHeight(Math.min(videoHeight, pageHeight));
    const emptySpacePx = Math.max(0, pageHeight - videoHeight);
    const emptySpaceVh = (emptySpacePx / pageHeight) * 100;
    setEmptySpace(emptySpaceVh);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateVideoDimensions);
    window.addEventListener("orientationchange", calculateVideoDimensions);
    calculateVideoDimensions();
    return () => {
      window.removeEventListener("resize", calculateVideoDimensions);
      window.removeEventListener("orientationchange", calculateVideoDimensions);
    };
  }, [calculateVideoDimensions]);

  const play = useCallback(
    (fromStart = false) => {
      if (!videoElementRef.current) return;
      if (fromStart) {
        videoElementRef.current.currentTime = 0;
      }
      var playPromise = videoElementRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            indicateReady();
            setPlayFailed(false);
          })
          .catch((error) => {
            indicateReady();
            setPlayFailed(true);
            console.error(error);
          });
        // Note: we call indicateReady() even if play() fails, because either way
        // we want to dismiss the video loading spinner.
      }
    },
    [videoElementRef, indicateReady],
  );

  const pause = useCallback(() => {
    if (videoElementRef.current) {
      videoElementRef.current.pause();
    }
  }, []);

  const isVideoPlaying = () => {
    const video = videoElementRef.current;
    return (
      video &&
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    );
  };

  const togglePlay = () => {
    if (isVideoPlaying()) {
      pause();
    } else {
      play();
    }
  };

  // show/hide based on subtitlesVisible
  useEffect(() => {
    if (swiper) {
      if (subtitlesVisible) {
        swiper.slideTo(1);
      } else {
        swiper.slideTo(0);
      }
    }
  }, [subtitlesVisible, swiper]);

  // On iOS, assets are unloaded when the tab is not visible.
  // This is a workaround to reload the video and poster when the tab is visible again.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (videoElementRef.current) {
          try {
            videoElementRef.current.load();
          } catch (error) {
            console.error("Error reloading video:", error);
          }
        }
        const posterBackgrounds = document.querySelectorAll(
          `[style*="background-image: url(${posterUrl})"]`,
        );
        posterBackgrounds.forEach((element) => {
          element.style.backgroundImage = `url(${posterUrl})`;
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [episode.path, fragment]);

  useEffect(() => {
    // Function to fetch the entire video file
    const fetchVideo = async () => {
      try {
        const response = await getRequest(
          `/video/${episode.path}/${fragment}.webm`,
          {},
          null,
          true,
        );
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideo();

    // Clean up the object URL when the component unmounts
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, []);

  const appropriateVideoElement = useCallback(() => {
    const videoElementOwned =
      videoElementRef.current &&
      videoElementRef.current.parentNode === videoContainerRef.current;
    if (videoElementOwned) return;

    if (!videoElementRef.current) {
      videoElementRef.current = document.createElement("video");
    }
    const video = videoElementRef.current;

    if (videoContainerRef.current && !videoElementOwned && videoUrl) {
      // Make sure the video is configured correctly:
      video.playsInline = true;
      video.preload = "auto";
      video.poster = posterUrl;
      video.src = videoUrl;

      video.style.position = "absolute";
      video.style.top = 0;
      video.style.left = 0;
      video.style.width = "100%";
      video.style.height = "100%";

      video.style.zIndex = 10;

      // Move the video element to our container.
      videoContainerRef.current.appendChild(video);

      video.load();
    }
  }, [videoElementRef, videoContainerRef, videoUrl, posterUrl]);

  useEffect(() => {
    const videoElementOwned =
      videoElementRef.current &&
      videoElementRef.current.parentNode === videoContainerRef.current;
    if (isActiveScene || isTransitionalScene) {
      appropriateVideoElement();
      // If a slide is coming into view, we want to make sure the video is ready
      // as soon as possible.
    }
    if (isActiveScene && videoUrl && !wasJustActiveScene.current) {
      play(true);
    } else if (wasJustActiveScene.current && !isActiveScene) {
      pause();
      // Note: we're doing it this way so only the active scene (or one that was
      // just active) ever issues a play() or pause() call.
      // Otherwise we can get in trouble with a play call getting aborted by a pause call.
    }

    if (!isLastSoloScene && wasJustLastSoloScene.current) {
      if (videoElementOwned) {
        videoElementRef.current.currentTime = 0;
      }
    }
    wasJustActiveScene.current = isActiveScene && videoUrl;
    wasJustLastSoloScene.current = isLastSoloScene;
  }, [
    isActiveScene,
    isTransitionalScene,
    isLastSoloScene,
    videoElementRef,
    pause,
    play,
    appropriateVideoElement,
    videoUrl,
  ]);

  // Auto-advance feature:
  useEffect(() => {
    const handleVideoEnded = () => {
      const isEmpty = (subtitle?.words || []).length === 0;
      const shouldAdvance = isEmpty
        ? getSetting(settings, "autoAdvanceEmptyScenes")
        : getSetting(settings, "autoAdvanceScenesWithSubtitles");

      if (shouldAdvance) {
        advanceSwiper();
      }
    };
    let videoEndedListenerAdded = false;
    if (isActiveScene) {
      videoElementRef.current.addEventListener("ended", handleVideoEnded);
      videoEndedListenerAdded = true;
    }

    return () => {
      if (videoEndedListenerAdded) {
        videoElementRef.current.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [subtitle, isActiveScene, advanceSwiper, videoElementRef]);

  // Keyboard shortcuts
  useEffect(() => {
    function onKeyDown(e) {
      if (!isActiveScene || popupState) {
        return;
      }

      switch (e.code) {
        case "Space":
          e.preventDefault();
          togglePlay();
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isActiveScene, togglePlay, popupState]);

  return (
    <Container>
      <VideoBackground>
        <div
          style={{
            width: "100vw",
            height: `${
              (VIDEO_ASPECT_RATIO.height / VIDEO_ASPECT_RATIO.width) * 100
            }vw`,
            position: "relative",
          }}
        >
          <PosterBackground poster={posterUrl} />
          <div ref={videoContainerRef}></div>
          {playFailed && <PlayButton onClick={togglePlay}></PlayButton>}
        </div>
      </VideoBackground>
      {popupState?.popup === "anki" && isActiveScene && (
        <Popup title="Create Anki Card" onClose={closePopups}>
          <AnkiCreator
            videoPath={videoPath}
            posterPath={videoMiddleFramePath}
            episode={episode}
            parentBreakdown={subtitle}
            childBreakdown={popupState.breakdown}
            morpheme={popupState.morpheme}
            sceneNr={index}
            closeCallback={closePopups}
            createCallback={() => createCardCallback(index)}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          />
        </Popup>
      )}
      {popupState?.popup === "deck" && isActiveScene && (
        <Popup title="Anki Deck for this Scene" onClose={closePopups}>
          <AnkiDeckPopup movieId={episode.id} breakdownIndex={subtitle.index} />
        </Popup>
      )}
      {popupState?.popup === "feedback" && isActiveScene && (
        <Popup title="Feedback" onClose={closePopups}>
          <FeedbackPopup
            episode={episode}
            closePopup={closePopups}
            breakdown={subtitle}
          />
        </Popup>
      )}
      {popupState?.popup === "chat" && isActiveScene && (
        <ChatPopup
          episode={episode}
          videoPath={episode.path + "/" + fragment + ".mp4"}
          parentBreakdown={subtitle}
          childBreakdown={popupState.breakdown}
          setPopupState={setPopupState}
        />
      )}
      <Swiper
        style={{ height: "100svh" }} // Swiper bug: https://github.com/nolimits4web/swiper/issues/3599
        direction="vertical"
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={setSwiper}
        initialSlide={1}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex === 1 && !subtitlesVisible) {
            setSubtitlesVisible(true);
          } else if (swiper.activeIndex === 0 && subtitlesVisible) {
            setSubtitlesVisible(false);
          }
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
      >
        <SwiperSlide>
          <div style={{ height: "100dvh" }} onClick={togglePlay} />
        </SwiperSlide>
        <SwiperSlide>
          <FlexWrapper>
            <FlexGrower onClick={togglePlay}></FlexGrower>
            {isActiveScene && (
              <PopoverManager
                popoverState={popoverState}
                setPopoverState={setPopoverState}
                popupState={popupState}
                setPopupState={setPopupState}
                breakdown={subtitle}
                getPopoverButton={getPopoverButton}
                getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
                setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
                breakdownScroll={breakdownScroll}
                setPopoverHasScrollbars={setPopoverHasScrollbars}
              />
            )}
            <BreakdownOverlay
              episode={episode}
              breakdown={subtitle}
              breakdownMenuOptions={breakdownMenuOptions}
              isVisible={true}
              animation={{}}
              preferredHeight={emptySpace}
              videoHeight={videoHeight}
              getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
              setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
              setBreakdownScroll={setBreakdownScroll}
              popoverState={popoverState}
              setPopoverState={setPopoverState}
              popupState={popupState}
              setPopupState={setPopupState}
              registerPopoverButton={registerPopoverButton}
              popoverHasScrollbars={popoverHasScrollbars}
            />
          </FlexWrapper>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}
