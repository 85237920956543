import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import IconDeck from "../assets/icons/cards-deck.svg";
import IconCards from "../assets/icons/cards.svg";
import ChatIcon from "../assets/icons/chat-white.svg";
import Row from "./layout/Row.js";
import { colors } from "./constants.js";

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 2rem 2rem;
  margin: -2rem 0rem;
  outline: none;
  cursor: pointer;
  pointer-events: all;

  svg path,
  p {
    transition: 0.2s all;
  }

  &:hover,
  &:focus {
    svg path {
      fill: ${colors.primary};
    }
    p {
      color: ${colors.primary};
    }
  }
`;

const RowWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export default function NumberIcon({ link, number, icon, onClick, label }) {
  const navigate = useNavigate();

  const iconDisplay = (
    <Row $gap="1rem" $center>
      <p>{number}</p>
      {icon === "deck" && <img src={IconDeck} alt="deck" />}
      {icon === "cards" && <img src={IconCards} alt="cards" />}
      {icon === "chat" && <img src={ChatIcon} alt="chat" />}
      {label && <p>{label}</p>}
    </Row>
  );

  if (onClick || link) {
    return (
      <Button onClick={() => (onClick ? onClick() : navigate(link))}>
        {iconDisplay}
      </Button>
    );
  }
  return <RowWrapper>{iconDisplay}</RowWrapper>;
}
