import React, { useEffect } from "react";
import Popover from "./Popover.js";
import PhrasePopover from "./PhrasePopover.js";

export default function PopoverManager({
  popoverState,
  setPopoverState,
  setPopoverHasScrollbars,
  breakdown,
  breakdownScroll,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  popupState,
  setPopupState,
  getPopoverButton,
}) {
  useEffect(() => {
    if (breakdown) {
      setPopoverState(null);
    }
  }, [breakdown, setPopoverState]);

  const parentButton =
    popoverState && getPopoverButton(popoverState.breakdown.index);

  useEffect(() => {
    if (!popoverState) {
      setPopoverHasScrollbars(false);
    }
  }, [popoverState, setPopoverHasScrollbars]);

  return (
    <>
      {popoverState && (
        <Popover
          popupState={popupState}
          popoverState={popoverState}
          parentButton={parentButton}
          onClose={() => setPopoverState(null)}
          breakdownScroll={breakdownScroll}
          setPopoverHasScrollbars={setPopoverHasScrollbars}
        >
          <PhrasePopover
            breakdown={popoverState.breakdown}
            getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
            setPopupState={setPopupState}
          />
        </Popover>
      )}
    </>
  );
}
